import { HttpClient, HttpMethods, HttpStatusCodes } from "@/domains/_app";
import {
  CalculateGeeEmissionInput,
  CalculateGeeEmissionOutput,
} from "@/domains/calculate-emissions";
import { NumberFormatter } from "@/domains/_shared/formatters";

export class EmissionCalculatorService {
  constructor(private readonly http: HttpClient) {}

  async calculate(
    input: CalculateGeeEmissionInput
  ): Promise<CalculateGeeEmissionOutput> {
    const response = await this.http.request<CalculateGeeEmissionOutput>({
      body: this.parseValues(input),
      method: HttpMethods.post,
      url: "/calculate",
    });

    switch (response.statusCode) {
      case HttpStatusCodes.ok:
        return response.body;
      default:
        throw new Error();
    }
  }

  private parseValues(input: CalculateGeeEmissionInput) {
    return {
      stationaryCombustion: this.convertToFloat(input.stationaryCombustion, [
        "amount",
      ]),
      mobileCombustion: this.convertToFloat(input.mobileCombustion, ["amount"]),
      fugitiveEmissions: this.convertToFloat(input.fugitiveEmissions, [
        "recharge",
      ]),
      fertilizers: input.fertilizers.map((fertilizer) => {
        return {
          totalArea: NumberFormatter.convertToFloat(fertilizer.totalArea),

          organicFertilizers: this.convertToFloat(
            fertilizer.organicFertilizer,
            ["amount"]
          ),
          nitrogenousFertilizers: this.convertToFloat(
            fertilizer.npkFertilizer,
            ["amount", "percentage"]
          ),
          ureaFertilizers: this.convertToFloat(fertilizer.ureaFertilizer, [
            "amount",
            "percentage",
          ]),
          limestoneFertilizers: this.convertToFloat(
            fertilizer.limestoneFertilizer,
            ["amount"]
          ),
        };
      }),
      pigFarming: this.convertToFloat(input.pigFarming, [
        "numberHousedAnimals",
        "numberHousedAnimals",
        "averageWeightHousedAnimals",
        "totalWeightHousedAnimals",
        "totalWeightSlaughteredAnimals",
        "weightGain",
        "age",
      ]),
      poultryFarming: this.convertToFloat(input.poultryFarming, [
        "age",
        "numberHousedAnimals",
        "numberDeadAnimals",
        "numberHousedAnimals",
        "averageWeightHousedAnimals",
        "totalWeightSlaughteredAnimals",
        "weightGainAnimal",
        "weightGain",
      ]),
    };
  }

  private convertToFloat<T>(list: T[], accessors: (keyof T)[]) {
    return list.map((input) => {
      const updatedInput: Record<keyof T, any> = { ...input };

      accessors.forEach((accessor) => {
        if (typeof input[accessor] === "string") {
          updatedInput[accessor] = NumberFormatter.convertToFloat(
            input[accessor] as string
          );
        }
      });

      return updatedInput;
    });
  }
}
