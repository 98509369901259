import { DataTable } from "@/domains/_shared/components";
import { usePoultryFarmingForm } from "@/domains/calculate-emissions";
import { useTranslation } from "@/domains/_shared/hooks";
import { DateFormatter, NumberFormatter } from "@/domains/_shared/formatters";

export function PoultryFarmingTable() {
  const { translate } = useTranslation("calculate_form.poultryfarming.table");
  const { poultryFarmingList } = usePoultryFarmingForm();

  return (
    <DataTable
      name="poultry_farming_table"
      title={translate("title", { size: poultryFarmingList.size })}
      empty_title={translate("empty.title")}
      empty_subtitle={translate("empty.subtitle")}
      columns={[
        translate("columns.id"),
        translate("columns.slaughter_date"),
        translate("columns.weight_gain"),
        translate("columns.age"),
      ]}
      items={poultryFarmingList.items.map((item) => [
        item.id,
        DateFormatter.fromDateToString(item.slaughterDate),
        NumberFormatter.format(item.weightGain),
        item.age,
      ])}
      isEmpty={poultryFarmingList.isEmpty}
      onRemove={poultryFarmingList.remove}
    />
  );
}
