import styled from "styled-components";

import { Grid } from "@/domains/_shared/components";

export const TwoColumns = styled(Grid)`
  grid-template-columns: 1fr 1fr;
`;

export const ThreeColumns = styled(Grid)`
  grid-template-columns: 1fr 1fr 1fr;
`;
