import { Button } from "@/domains/_shared/components";
import { useTranslation } from "@/domains/_shared/hooks";
import {
  FugitiveEmissionsTable,
  StepFormLayout,
  useFugitiveEmissionsForm,
} from "@/domains/calculate-emissions";

import { Form } from "./Form";

export function FugitiveEmissions() {
  const { translate } = useTranslation("calculate_form.fugitive_emissions");

  const { addFugitiveEmissions } = useFugitiveEmissionsForm();

  return (
    <StepFormLayout
      title={<h2>{translate("title")}</h2>}
      table={<FugitiveEmissionsTable />}
      form={<Form />}
      button={
        <Button name="add_button" onClick={addFugitiveEmissions}>
          {translate("button.label")}
        </Button>
      }
    />
  );
}
