import { useStepFormBuilder } from "@/domains/calculate-emissions";
import {
  PoultryFarming,
  PoultryFarmingForm,
} from "@/domains/calculate-emissions/context";
import { Validator } from "@/domains/_shared/validators";

export function usePoultryFarming(): PoultryFarmingForm {
  const { updateValueCurry, ...values } = useStepFormBuilder<PoultryFarming>({
    translationKey: "calculate_form.poultryfarming.fields",
    defaultValues: {
      id: "",
      slaughterDate: new Date(),
      age: "0",

      numberHousedAnimals: "0",
      numberSlaughteredAnimals: "0",
      numberDeadAnimals: "0",

      averageWeightHousedAnimals: "0,00",
      totalWeightSlaughteredAnimals: "0,00",

      weightGainAnimal: "0,00",
      weightGain: "0,00",
    },
    validator: new Validator<PoultryFarming>({
      id: ["required"],

      slaughterDate: ["required_date"],
      age: ["above_zero"],

      numberHousedAnimals: ["above_zero"],
      numberDeadAnimals: ["above_zero"],
      numberSlaughteredAnimals: ["above_zero"],

      averageWeightHousedAnimals: ["above_zero"],
      totalWeightSlaughteredAnimals: ["above_zero"],

      weightGain: ["above_zero"],
      weightGainAnimal: ["above_zero"],
    }),
  });

  return {
    ...values,

    addFarmingToList: values.addItemToArray,
    poultryFarmingList: {
      items: values.array,
      isEmpty: values.isEmpty,
      size: values.size,
      remove: values.removeItem,
    },

    updateId: updateValueCurry("id"),
    updateSlaughterDate: (date: Date) =>
      values.updateValue("slaughterDate", date),

    updateNumberHousedAnimals: updateValueCurry("numberHousedAnimals"),
    updateNumberSlaughteredAnimals: updateValueCurry(
      "numberSlaughteredAnimals"
    ),
    updateNumberDeadAnimals: updateValueCurry("numberDeadAnimals"),

    updateAverageWeightHousedAnimals: updateValueCurry(
      "averageWeightHousedAnimals"
    ),
    updateTotalWeightSlaughteredAnimals: updateValueCurry(
      "totalWeightSlaughteredAnimals"
    ),

    updateWeightGain: updateValueCurry("weightGain"),
    updateWeightGainAnimal: updateValueCurry("weightGainAnimal"),
    updateAge: updateValueCurry("age"),
  };
}
