import { PigFarmingExtraEmissions } from "@/domains/calculate-emissions/models";
import { useTranslateResult } from "@/domains/calculate-emissions";

import * as Styles from "../Results.styles";

interface PigFarmingTotalsProps extends PigFarmingExtraEmissions {}

export function PigFarmingTotals(props: PigFarmingTotalsProps) {
  const { translate, toDisplayNumber } = useTranslateResult();

  return (
    <Styles.TwoColumns>
      <Styles.EmissionTitle type="fossil">
        {translate("pig_farming_totals.total_enteric")}

        <Styles.EmissionInfo>
          {toDisplayNumber(props.totalEntericEmissionsByBatchMetricTonnes)}
        </Styles.EmissionInfo>
      </Styles.EmissionTitle>

      <Styles.EmissionTitle type="bio">
        {translate("pig_farming_totals.total_waste")}

        <Styles.EmissionInfo>
          {toDisplayNumber(props.totalWasteEmissionsByBatchMetricTonnes)}
        </Styles.EmissionInfo>
      </Styles.EmissionTitle>
    </Styles.TwoColumns>
  );
}
