import { Select } from "@/domains/_shared/components";
import { StationaryCombustionFuels } from "@/domains/calculate-emissions/constrains";

import { useTranslateFuel } from "../hooks";

const FUELS = Object.values(StationaryCombustionFuels);

export function StationaryFuelSelect({ fuel, updateFuel, label }: any) {
  const { translateFuel } = useTranslateFuel();

  const options = FUELS.map((fuel) => ({
    value: fuel,
    label: translateFuel(fuel),
  }));

  return (
    <Select
      name="fuel_select_input"
      label={label}
      value={fuel}
      onChange={updateFuel}
      options={options}
    />
  );
}
