import CurrencyInput from "react-currency-input-field";

import { FormField } from "@/domains/_shared/components";

import * as Styles from "../NumberField/NumberField.styles";

import { ExternalNumberFieldProps } from "./ExternalNumberField.types";

export function ExternalNumberField({
  onChange,
  ...props
}: ExternalNumberFieldProps) {
  const dispatchOnChange = (value: string | undefined) => {
    if (value) onChange?.(value);
  };

  return (
    <FormField {...props}>
      <Styles.Container>
        <CurrencyInput
          {...props}
          onValueChange={dispatchOnChange}
          className={props.error && "field_error"}
        />
      </Styles.Container>
    </FormField>
  );
}
