import React, { useMemo } from "react";

import { login } from "@/domains/authentication/services";
import { Container } from "@/domains/_app";

import { AuthContext } from "./auth.context";

export const AuthProvider: React.ProviderFC = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = React.useState(
    !!Container.TokenStorage.token
  );

  const handleLogin = async (password: string) => {
    const token = await login(password);

    setIsAuthenticated(token);

    return token;
  };

  const value = useMemo(
    () => ({
      isAuthenticated,
      onLogin: handleLogin,
    }),
    [isAuthenticated]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
