import React from "react";

import { NumberFormatter } from "@/domains/_shared/formatters";

import { ExternalNumberField } from "../ExternalNumberField";
import { NumberFieldProps } from "../NumberField/NumberField.types";

export const PercentageField: React.FC<NumberFieldProps> = ({ ...props }) => {
  return (
    <ExternalNumberField
      {...props}
      allowDecimals
      allowNegativeValue={false}
      decimalsLimit={2}
      decimalSeparator=","
      groupSeparator="."
      suffix="%"
      transformRawValue={(value: string) => {
        const digit = NumberFormatter.convertToFloat(value.replace("%", ""));

        if (!digit && digit !== 0) return "0";

        if (digit >= 100) return "100";

        return digit + "%";
      }}
    />
  );
};
