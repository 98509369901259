export const theme = {
  colors: {
    background: "#f6f8fa",
    background2: "#e1e4e8",
    background_light: "#fff",

    text: "#24292e",
    primary: "#0366d6",
    primary_bold: "#0156aa",
    secondary: "#6a737d",

    green: "#28a745",
    green_bold: "#218838",

    grey_1: "#CCC",
    grey_50: "#f9fafb",
    grey_100: "#f3f4f6",
    grey_150: "#f4f4f4",
    grey_200: "#e5e7eb",
    grey_300: "#d1d5db",
    grey_400: "#9ca3af",
    grey_500: "#6b7280",
    grey_600: "#4b5563",
    grey_700: "#374151",
    grey_800: "#1f2937",
    grey_900: "#111827",
    grey_950: "#030712",

    red_50: "#fef2f2",
    red_100: "#fee2e2",
    red_200: "#fecaca",
    red_300: "#fca5a5",
    red_400: "#f87171",
    red_500: "#ef4444",
    red_600: "#dc2626",
    red_700: "#b91c1c",
    red_800: "#991b1b",
    red_900: "#7f1d1d",
    red_950: "#450a0a",

    blue_50: "#eff6ff",
    blue_100: "#dbeafe",
    blue_200: "#bfdbfe",
    blue_300: "#93c5fd",
    blue_400: "#60a5fa",
    blue_500: "#3b82f6",
    blue_600: "#2563eb",
    blue_700: "#1d4ed8",
    blue_800: "#1e40af",
    blue_900: "#1e3a8a",
    blue_950: "#172554",

    green_50: "#f0fdf4",
    green_100: "#dcfce7",
    green_200: "#bbf7d0",
    green_300: "#86efac",
    green_400: "#4ade80",
    green_500: "#22c55e",
    green_600: "#16a34a",
    green_700: "#15803d",
    green_800: "#166534",
    green_900: "#14532d",
    green_950: "#052e16",

    emerald_50: "#ecfdf5",
    emerald_100: "#d1fae5",
    emerald_200: "#a7f3d0",
    emerald_300: "#6ee7b7",
    emerald_400: "#34d399",
    emerald_500: "#10b981",
    emerald_600: "#059669",
    emerald_700: "#047857",
    emerald_800: "#065f46",
    emerald_900: "#064e3b",
    emerald_950: "#022c22",

    loading: {
      base: "#eaeaea",
      highlight: "#f2f2f2",
    },
  },

  components: {
    shared: {
      form: {
        font_size: "18px",
      },
    },
  },
};
