import { GasOrCompound } from "@/domains/calculate-emissions/constrains";
import { Select } from "@/domains/_shared/components";
import { useTranslation } from "@/domains/_shared/hooks";

const GAS_OR_COMPOUNDS = Object.values(GasOrCompound);

export function GasOrCompoundSelect(props: {
  value: GasOrCompound;
  onChange: (gas: GasOrCompound) => void;
  label: string;
}) {
  const { translate } = useTranslation("constrains.gas_or_compound");

  const options = GAS_OR_COMPOUNDS.map((gas) => ({
    value: gas,
    label: translate(gas),
  }));

  return (
    <Select name="gas_or_compound_select_input" options={options} {...props} />
  );
}
