import styled from "styled-components";

export const Error = styled.span`
  color: ${(props) => props.theme.colors.red_600};
  font-size: 16px;

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  animation: fade-in 0.3s ease-in-out;
`;
