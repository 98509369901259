import React from "react";

import { FormField } from "../FormField";

import { SelectProps } from "./Select.types";
import * as Styled from "./Select.styles";

export const Select: React.ProviderFC<SelectProps> = ({
  name,
  label,

  value,
  onChange,
  options,
}) => {
  const dispatchOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onChange?.(event.target.value);
  };

  return (
    <FormField name={name} label={label}>
      <Styled.Select name={name} value={value} onChange={dispatchOnChange}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Styled.Select>
    </FormField>
  );
};
