import React from "react";

import { StepperProps, StepProps } from "./Stepper.types";

export const Stepper: React.FC<StepperProps> = ({ current, children }) => {
  const steps = React.Children.toArray(
    children
  ) as React.ReactElement<StepProps>[];

  const isCurrent = (step: number) => step === current;

  return (
    <div>
      {steps.map((step) => (
        <div key={step.props.step}>{isCurrent(step.props.step) && step}</div>
      ))}
    </div>
  );
};
