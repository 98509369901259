import styled, { css } from "styled-components";

import { Variants } from "./Button.types";

export const Button = styled.button<{ variant: Variants }>`
  font-size: ${(props) => props.theme.components.shared.form.font_size};

  padding: 10px 16px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.background_light};
  background-color: ${(props) => props.theme.colors.text};
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.colors.grey_700};
  }

  &:disabled {
    background-color: ${(props) => props.theme.colors.secondary};
    cursor: not-allowed;
  }

  ${(props) =>
    props.variant === Variants.secondary &&
    css`
      color: ${(props) => props.theme.colors.text};
      background-color: ${(props) => props.theme.colors.grey_100};

      &:hover:not(:disabled) {
        background-color: ${(props) => props.theme.colors.grey_200};
      }

      &:disabled {
        color: ${(props) => props.theme.colors.grey_400};
        background-color: ${(props) => props.theme.colors.grey_100};
        cursor: not-allowed;
      }
    `}
`;
