import { useTranslation } from "@/domains/_shared/hooks";
import { NumberFormatter } from "@/domains/_shared/formatters";

export function useTranslateResult() {
  const { translate } = useTranslation("calculate_form.result.totals_by_step");

  const toDisplayNumber = (number: number) => {
    return translate("to_tons", {
      tons: NumberFormatter.format(number),
    });
  };

  const isNotBlank = (number: number | undefined) => {
    return number !== undefined;
  };

  return {
    translate,
    toDisplayNumber,
    isNotBlank,
  };
}
