export const Portuguese = {
  calculate_form: {
    stepper: {
      previous_button: {
        label: "voltar",
      },
      next_button: {
        label: "continuar",
      },
    },

    stationary_combustion: {
      title: "Combustão estacionaria",
      subtitle: "Fontes estacionárias de combustão",

      table: {
        empty: {
          title: "Registros de Combustão Estacionárias adicionados (0):",
          subtitle: "Sem Registros",
        },

        title: "Registros de Combustão Estacionárias adicionados ({{size}}):",
        columns: {
          description:
            "$t(calculate_form.stationary_combustion.fields.description.label)",
          fuel: "$t(calculate_form.stationary_combustion.fields.fuel.label)",
          amount:
            "$t(calculate_form.stationary_combustion.fields.amount.label_raw)",
        },
      },

      fields: {
        sector: {
          label: "Fatores de emissão para o setor:",
        },
        description: {
          label: "Descricão da Fonte",

          errors: {
            blank:
              '$t(constrains.errors.required_f, {"field": "Descricão da Fonte"})',
          },
        },
        fuel: {
          label: "Combustível utilizado",
        },
        amount: {
          label:
            "$t(calculate_form.stationary_combustion.fields.amount.label_raw) ({{unit}})",
          label_raw: "Quantidade Consumida",

          errors: {
            below_zero:
              '$t(constrains.errors.required_f, { "field": "Quantidade Consumida" })',
          },
        },
      },

      button: {
        label: "Adicionar",
      },
    },

    mobile_combustion: {
      title: "Combustão móvel",

      table: {
        empty: {
          title:
            '$t(calculate_form.mobile_combustion.table.title, {"size": "0" })',
          subtitle: "Sem Registros",
        },

        title: "Registros de Combustão Móvel adicionados ({{size}}):",
        columns: {
          description:
            "$t(calculate_form.mobile_combustion.fields.description.label)",
          fuel: "$t(calculate_form.mobile_combustion.fields.fuel.label)",
          amount:
            "$t(calculate_form.mobile_combustion.fields.amount.label_raw)",
        },
      },

      fields: {
        description: {
          label: "Descricão da Fonte",

          errors: {
            blank:
              '$t(constrains.errors.required_f, {"field": "Descricão da Fonte"})',
          },
        },
        fuel: {
          label: "Combustível utilizado",
        },
        amount: {
          label:
            "$t(calculate_form.mobile_combustion.fields.amount.label_raw) ({{unit}})",
          label_raw: "Quantidade Consumida",

          errors: {
            below_zero:
              '$t(constrains.errors.required_f, {"field": "Quantidade Consumida"})',
          },
        },
      },

      button: {
        label: "Adicionar",
      },
    },

    fugitive_emissions: {
      title: "Emissões fugitivas",

      table: {
        empty: {
          title:
            '$t(calculate_form.fugitive_emissions.table.title, {"size": "0" })',
          subtitle: "Sem Registros",
        },

        title: "Registros de Emissões fugitivas ({{size}}):",
        columns: {
          gas_or_compound:
            "$t(calculate_form.fugitive_emissions.fields.gas_or_compound.label)",
          recharge:
            "$t(calculate_form.fugitive_emissions.fields.recharge.label)",
        },
      },

      fields: {
        gas_or_compound: {
          label: "Gás ou composto",
        },
        recharge: {
          label: "Recarga (kg)",

          errors: {
            below_zero:
              '$t(constrains.errors.required_f, {"field": "Recarga"})',
          },
        },
      },

      button: {
        label: "Adicionar",
      },
    },

    fertilizers: {
      title: "Fertilizantes",

      table: {
        empty: {
          title: '$t(calculate_form.fertilizers.table.title, {"size": "0" })',
          subtitle: "Sem Registros",
        },

        title: "Registros de Fertilizantes em Quantidade Total ({{size}}):",

        column: {
          area: "Área Total (ha)",
          culture: "Cultura",
          organic_fertilizer: "Orgânico (kg)",
          nitrogenous_fertilizer: "Nitrogenados Sintéticos (kg)",
          urea: "Ureia (kg)",
          limestone: "Calcário (kg)",
        },
      },

      fields: {
        total_area: {
          label: "Área Total (hectares)",

          errors: {
            below_zero:
              '$t(constrains.errors.required_f, {"field": "Área Total"})',
          },
        },

        total: {
          errors: {
            below_zero:
              '$t(constrains.errors.required_f, {"field": "Área Total"})',
          },
        },

        culture: {
          label: "Cultura",

          errors: {
            blank: '$t(constrains.errors.required_f, {"field": "Cultura"})',
          },
        },

        amount: {
          label: "Quantidade aplicada (kg)",

          errors: {
            below_zero:
              '$t(constrains.errors.required_f, {"field": "Quantidade aplicada"})',
          },
        },

        percentage: {
          label: "Percentual de Nitrogênio",

          errors: {
            below_zero:
              '$t(constrains.errors.required_m, {"field": "Percentual de Nitrogênio"})',
          },
        },

        organic_fertilizer: {
          title: "Fertilizantes Orgânicos",
          label: "Fertilizante",
        },

        nitrogenous_fertilizer: {
          title: "Fertilizantes Nitrogenados Sintéticos N-P-K",
          label: "Percentual de Nitrogênio (%)",
        },

        urea: {
          title: "Fertilizantes Nitrogenados Ureia",
          label: "Percentual de Nitrogênio (%)",
        },

        limestone: {
          title: "Calcário",
        },
      },

      add_button: {
        label: "Adicionar",
      },
    },

    pigfarming: {
      title: "Suinocultura",
      subtitle: "Lote",

      fields: {
        id: {
          label: "Identificação",

          errors: {
            blank:
              '$t(constrains.errors.required_f, {"field": "Identificação" })',
          },
        },

        treatment: {
          label: "Sistema de Tratamento de Dejetos",
        },
        slaughter_date: {
          label: "Data de Abate",

          errors: {
            blank:
              '$t(constrains.errors.required_f, {"field": "Data de Abate" })',
          },
        },
        number_housed_animals: {
          label: "Número de Animais Alojados",

          errors: {
            below_zero:
              '$t(constrains.errors.required_m, {"field": "Número de Animais Alojados" })',
          },
        },
        number_slaughtered_animals: {
          label: "Número de Animais Terminados",

          errors: {
            below_zero:
              '$t(constrains.errors.required_m, {"field": "Número de Animais Terminados" })',
          },
        },

        average_weight_housed_animals: {
          label: "Peso Médio por Suíno Alojado (kg)",

          errors: {
            below_zero:
              '$t(constrains.errors.required_m, {"field": "Peso Médio por Suíno Alojado" })',
          },
        },
        total_weight_housed_animals: {
          label: "Peso Total de Suínos Alojados (kg)",

          errors: {
            below_zero:
              '$t(constrains.errors.required_m, {"field": "Peso Total de Suínos Alojados" })',
          },
        },
        total_weight_slaughtered_animals: {
          label: "Peso Total de Suínos Terminados (kg)",

          errors: {
            below_zero:
              '$t(constrains.errors.required_m, {"field": "Peso Total de Suínos Terminados" })',
          },
        },

        weight_gain: {
          label: "Ganho Peso (kg)",

          errors: {
            below_zero:
              '$t(constrains.errors.required_m, {"field": "Ganho Peso" })',
          },
        },
        age: {
          label: "Idade (dias em alojamento)",

          errors: {
            below_zero: '$t(constrains.errors.required_f, {"field": "Idade" })',
          },
        },

        button: {
          label: "adicionar",
        },

        errors: {
          id: {
            blank:
              '$t(constrains.errors.required_f, {"field": "Identificação" })',
          },

          treatment: {
            label: "O Sistema de Tratamento de Dejetos é obrigatório",
          },
          slaughter_date: {
            label: "Data de Abate",
          },
          number_housed_animals: {
            label: "Número de Animais Alojados",
          },
          number_slaughtered_animals: {
            label: "Número de Animais Terminados",
          },

          average_weight_housed_animals: {
            label: "Peso Médio por Suíno Alojado (kg)",
          },
          total_weight_housed_animals: {
            label: "Peso Total de Suínos Alojados (kg)",
          },
          total_weight_slaughtered_animals: {
            label: "Peso Total de Suínos Terminados (kg)",
          },

          weight_gain: {
            label: "Ganho Peso (kg)",
          },
          age: {
            label: "Idade (dias em alojamento)",
          },
        },
      },

      table: {
        title: "Registros de Suinocultura ({{size}}):",

        empty: {
          title: '$t(calculate_form.pigfarming.table.title, {"size": "0" })',
          subtitle: "Sem Registros",
        },

        columns: {
          treatment: "Tratamento",
          slaughter_date: "Data de Abate",
          weight_gain: "Ganho Peso (kg)",
          age: "Idade",
        },
      },
    },

    poultryfarming: {
      title: "Avicultura",
      subtitle: "Lote",

      fields: {
        id: {
          label: "Identificação",

          errors: {
            blank:
              '$t(constrains.errors.required_f, {"field": "Identificação" })',
          },
        },

        slaughter_date: {
          label: "Data de Abate",

          errors: {
            blank:
              '$t(constrains.errors.required_f, {"field": "Data de Abate" })',
          },
        },
        age: {
          label: "Idade (dias em alojamento)",

          errors: {
            below_zero: '$t(constrains.errors.required_f, {"field": "Idade" })',
          },
        },

        number_housed_animals: {
          label: "Número de Animais Alojados",

          errors: {
            below_zero:
              '$t(constrains.errors.required_m, {"field": "Número de Animais Alojados" })',
          },
        },
        number_slaughtered_animals: {
          label: "Número de Animais Terminados",

          errors: {
            below_zero:
              '$t(constrains.errors.required_m, {"field": "Número de Animais Terminados" })',
          },
        },
        number_dead_animals: {
          label: "Número de Animais Mortos",

          errors: {
            below_zero:
              '$t(constrains.errors.required_m, {"field": "Número de Animais Mortos" })',
          },
        },

        average_weight_housed_animals: {
          label: "Peso médio por Ave Alojada (kg)",

          errors: {
            below_zero:
              '$t(constrains.errors.required_m, {"field": "Peso médio por Ave Alojada" })',
          },
        },
        total_weight_slaughtered_animals: {
          label: "Peso Total de Aves Terminadas (kg)",

          errors: {
            below_zero:
              '$t(constrains.errors.required_m, {"field": "Peso Total de Aves Terminadas" })',
          },
        },

        weight_gain_animal: {
          label: "Ganho Peso por Ave (kg)",

          errors: {
            below_zero:
              '$t(constrains.errors.required_m, {"field": "Ganho Peso por Ave" })',
          },
        },
        weight_gain: {
          label: "Ganho Peso Total (kg)",

          errors: {
            below_zero:
              '$t(constrains.errors.required_m, {"field": "Ganho Peso Total" })',
          },
        },

        button: {
          label: "adicionar",
        },
      },

      table: {
        title: "Registros de Avicultura ({{size}}):",

        empty: {
          title: '$t(calculate_form.pigfarming.table.title, {"size": "0" })',
          subtitle: "Sem Registros",
        },

        columns: {
          id: "Identificação",
          slaughter_date: "Data de Abate",
          weight_gain: "Ganho Peso Total (kg)",
          age: "Idade",
        },
      },
    },

    overview: {
      title: "Resumo",

      button: {
        label: "Calcular",
      },
    },

    result: {
      titles: {
        stationary_combustion: "Emissões totais por Combustão Estacionária",
        mobile_combustion: "Emissões totais por Combustão Móvel",
        fugitive_emissions: "Emissões totais por Emissões Fugitivas",
        fertilizers: "Emissões totais por Fertilizantes",
        pig_farming: "Emissões totais por Suinocultura",
        poultry_farming: "Emissões totais por Avicultura",
      },

      totals_by_step: {
        to_tons: "{{tons}} Toneladas",

        total_co2_equivalent: "Emissões totais em CO2 equivalente:",
        total_biogenic_co2: "Emissões totais em CO2 biogênico:",

        pig_farming_totals: {
          total_enteric: "Emissões Etéricas totais:",
          total_waste: "Emissões totais de Dejetos:",
        },
      },
    },
  },

  login: {
    password: {
      label: "Senha de acesso",
    },

    button: {
      label: "entrar",
    },
  },

  constrains: {
    errors: {
      required_f: "A {{field}} é obrigatória",
      required_m: "O {{field}} é obrigatório",
    },

    data_table: {
      columns: {
        remove: "Remover",
      },
    },

    stationary_combustion_sector: {
      energy: "Energia",
      manufacturing_or_construction: "Manufatura ou Construção",
      commercial_or_institutional: "Comercial ou Institucional",
      residential_agriculture_forestry_or_fishing:
        "Residencial, Agricultura, Florestal ou Pesca",
    },

    fuel_type: {
      acetylene: "Acetileno",
      tar: "Alcatrão",
      asphalts: "Asfaltos",
      bagasse_of_cane: "Bagaço de Cana",
      biodiesel_b100: "Biodiesel (B100)",
      biogas_other: "Biogás (outros)",
      biogas_landfill: "Biogás de Aterro",
      biomethane: "Biometano",
      sugarcane_juice: "Caldo de Cana",
      imported_metallurgical_coal: "Carvão Metalúrgico Importado",
      domestic_metallurgical_coal: "Carvão Metalúrgico Nacional",
      steam_coal_3100_kcal_kg: "Carvão Vapor 3100 kcal/kg",
      steam_coal_3300_kcal_kg: "Carvão Vapor 3300 kcal/kg",
      steam_coal_3700_kcal_kg: "Carvão Vapor 3700 kcal/kg",
      steam_coal_4200_kcal_kg: "Carvão Vapor 4200 kcal/kg",
      steam_coal_4500_kcal_kg: "Carvão Vapor 4500 kcal/kg",
      steam_coal_4700_kcal_kg: "Carvão Vapor 4700 kcal/kg",
      steam_coal_5200_kcal_kg: "Carvão Vapor 5200 kcal/kg",
      steam_coal_5900_kcal_kg: "Carvão Vapor 5900 kcal/kg",
      steam_coal_6000_kcal_kg: "Carvão Vapor 6000 kcal/kg",
      unspecified_steam_coal: "Carvão Vapor sem Especificação",
      charcoal: "Carvão Vegetal",
      coal_coke: "Coque de Carvão Mineral",
      petroleum_coke: "Coque de Petróleo",
      ethane: "Etano",
      ethanol: "Etanol",
      coking_gas: "Gás de Coqueria",
      refinery_gas: "Gás de Refinaria",
      liquefied_petroleum_gas_glp: "Gás Liquefeito de Petróleo (GLP)",
      dry_natural_gas: "Gás Natural Seco",
      wet_natural_gas: "Gás Natural Úmido",
      automotive_gasoline_commercial: "Gasolina Automotiva (comercial)",
      automotive_gasoline_pure: "Gasolina Automotiva (pura)",
      aviation_gasoline: "Gasolina de Aviação",
      commercial_firewood: "Lenha Comercial",
      black_liquor: "Licor Negro (Lixívia)",
      liquefied_natural_gas_lgn: "Líquidos de Gás Natural (LGN)",
      lubricants: "Lubrificantes",
      molasses: "Melaço",
      naphtha: "Nafta",
      fuel_oil: "Óleo Combustível",
      shale_oil: "Óleo de Xisto",
      diesel_oil_commercial: "Óleo Diesel (comercial)",
      diesel_oil_pure: "Óleo Diesel (puro)",
      residual_oils: "Óleos Residuais",
      other_petroleum_products: "Outros Produtos de Petróleo",
      paraffin: "Parafina",
      crude_oil: "Petróleo Bruto",
      aviation_kerosene: "Querosene de Aviação",
      illuminating_kerosene: "Querosene Iluminante",
      industrial_waste: "Resíduos Industriais",
      municipal_waste_biomass_fraction: "Resíduos Municipais (fração biomassa)",
      municipal_waste_non_biomass_fraction:
        "Resíduos Municipais (fração não-biomassa)",
      vegetable_waste: "Resíduos Vegetais",
      solvents: "Solventes",
      peat: "Turfa",
      oil_shale_and_tar_sands: "Xisto Betuminoso e Areias Betuminosas",
      vehicle_natural_gas_gnv: "Gás Natural Veicular (GNV)",
    },

    metricUnit: {
      kg: "kg",
      m3: "m³",
      t: "Toneladas",
      l: "Litros",
      tj: "TJ",
    },

    gas_or_compound: {
      CO2: "Dióxido de carbono (CO2)",
      CH4: "Metano (CH4)",
      N2O: "Óxido nitroso (N2O)",
      HFC_23: "HFC-23",
      HFC_32: "HFC-32",
      HFC_41: "HFC-41",
      HFC_125: "HFC-125",
      HFC_134: "HFC-134",
      HFC_134a: "HFC-134a",
      HFC_143: "HFC-143",
      HFC_143a: "HFC-143a",
      HFC_152: "HFC-152",
      HFC_152a: "HFC-152a",
      HFC_161: "HFC-161",
      HFC_227ea: "HFC-227ea",
      HFC_236cb: "HFC-236cb",
      HFC_236ea: "HFC-236ea",
      HFC_236fa: "HFC-236fa",
      HFC_245ca: "HFC-245ca",
      HFC_245fa: "HFC-245fa",
      HFC_365mfc: "HFC-365mfc",
      HFC_43_10mee: "HFC-43-10mee",
      SF6: "Hexafluoreto de enxofre (SF6)",
      NF3: "Trifluoreto de nitrogênio (NF3)",
      PFC_14: "PFC-14",
      PFC_116: "PFC-116",
      PFC_218: "PFC-218",
      PFC_318: "PFC-318",
      PFC_3_1_10: "PFC-3-1-10",
      PFC_4_1_12: "PFC-4-1-12",
      PFC_5_1_14: "PFC-5-1-14",
      PFC_9_1_18: "PFC-9-1-18",
      SF5CF3: "Trifluorometil pentafluoreto de enxofre (SF5CF3)",
      c_C3F6: "Perfluorociclopropano (c-C3F6)",
      R_400: "R-400",
      R_401A: "R-401A",
      R_401B: "R-401B",
      R_401C: "R-401C",
      R_402A: "R-402A",
      R_402B: "R-402B",
      R_403A: "R-403A",
      R_403B: "R-403B",
      R_404A: "R-404A",
      R_405A: "R-405A",
      R_406A: "R-406A",
      R_407A: "R-407A",
      R_407B: "R-407B",
      R_407C: "R-407C",
      R_407D: "R-407D",
      R_407E: "R-407E",
      R_407F: "R-407F",
      R_407G: "R-407G",
      R_407H: "R-407H",
      R_407I: "R-407I",
      R_408A: "R-408A",
      R_409A: "R-409A",
      R_409B: "R-409B",
      R_410A: "R-410A",
      R_410B: "R-410B",
      R_411A: "R-411A",
      R_411B: "R-411B",
      R_412A: "R-412A",
      R_413A: "R-413A",
      R_414A: "R-414A",
      R_414B: "R-414B",
      R_415A: "R-415A",
      R_415B: "R-415B",
      R_416A: "R-416A",
      R_417A: "R-417A",
      R_417B: "R-417B",
      R_417C: "R-417C",
      R_418A: "R-418A",
      R_419A: "R-419A",
      R_419B: "R-419B",
      R_420A: "R-420A",
      R_421A: "R-421A",
      R_421B: "R-421B",
      R_422A: "R-422A",
      R_422B: "R-422B",
      R_422C: "R-422C",
      R_422D: "R-422D",
      R_422E: "R-422E",
      R_423A: "R-423A",
      R_424A: "R-424A",
      R_425A: "R-425A",
      R_426A: "R-426A",
      R_427A: "R-427A",
      R_428A: "R-428A",
      R_429A: "R-429A",
      R_430A: "R-430A",
      R_431A: "R-431A",
      R_432A: "R-432A",
      R_433A: "R-433A",
      R_433B: "R-433B",
      R_433C: "R-433C",
      R_434A: "R-434A",
      R_435A: "R-435A",
      R_436A: "R-436A",
      R_436B: "R-436B",
      R_436C: "R-436C",
      R_437A: "R-437A",
      R_438A: "R-438A",
      R_439A: "R-439A",
      R_440A: "R-440A",
      R_441A: "R-441A",
      R_442A: "R-442A",
      R_443A: "R-443A",
      R_444A: "R-444A",
      R_444B: "R-444B",
      R_445A: "R-445A",
      R_446A: "R-446A",
      R_447A: "R-447A",
      R_447B: "R-447B",
      R_448A: "R-448A",
      R_449A: "R-449A",
      R_449B: "R-449B",
      R_449C: "R-449C",
      R_450A: "R-450A",
      R_451A: "R-451A",
      R_451B: "R-451B",
      R_452A: "R-452A",
      R_452B: "R-452B",
      R_452C: "R-452C",
      R_453A: "R-453A",
      R_454A: "R-454A",
      R_454B: "R-454B",
      R_454C: "R-454C",
      R_455A: "R-455A",
      R_456A: "R-456A",
      R_457A: "R-457A",
      R_458A: "R-458A",
      R_459A: "R-459A",
      R_459B: "R-459B",
      R_460A: "R-460A",
      R_460B: "R-460B",
      R_460C: "R-460C",
      R_461A: "R-461A",
      R_462A: "R-462A",
      R_463A: "R-463A",
      R_464A: "R-464A",
      R_465A: "R-465A",
      R_500: "R-500",
      R_501: "R-501",
      R_502: "R-502",
      R_503: "R-503",
      R_504: "R-504",
      R_505: "R-505",
      R_506: "R-506",
      R_507orR_507A: "R-507 ou R-507A",
      R_508A: "R-508A",
      R_508B: "R-508B",
      R_509orR_509A: "R-509 ou R-509A",
      R_510A: "R-510A",
      R_511A: "R-511A",
      R_512A: "R-512A",
      R_513A: "R-513A",
      R_513B: "R-513B",
      R_514A: "R-514A",
      R_515A: "R-515A",
      R_516A: "R-516A",
    },

    organic_fertilizer: {
      manure: "Esterco (bovino, equino, suíno, ovinos)",
      poultry_manure: "Esterco de aves",
      organic_compost: "Composto orgânico",
      general: "Geral",

      vinasse: "Vinhaça",
      filter_cake: "Torta de filtro",
    },

    pig_farming_treatments: {
      uncovered_anaerobic_lagoon: "Lagoa Anaeróbia Descoberta",
      liquid_slurry_pit_storage_above:
        "Armazenamento líquido/lodo e em poços abaixo de confinamentos de animais > 1 mês",
      liquid_slurry_pit_storage_below:
        "Armazenamento líquido/lodo e em poços abaixo de confinamentos de animais < 1 mês",
      solid_storage: "Armazenamento Sólido",
      dry_lot: "Lote Seco",
      daily_spread: "Espalhamento Diário",
      anaerobic_digestion_biogas: "Digestão Anaeróbia - Biogás",
      burned_for_fuel: "Queimado como Combustível",
    },
  },
};
