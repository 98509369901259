import { RuleValidator } from "./validator.types";

export class RequiredValidator<T> implements RuleValidator<T> {
  isNotValid(value: T): boolean {
    return typeof value !== "string" || value.trim() === "";
  }

  getError(field: string): string {
    return `${field}.errors.blank`;
  }
}
