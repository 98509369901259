import {
  CalculateGeeEmissionInput,
  CalculateGeeEmissionOutput,
} from "@/domains/calculate-emissions/services/emission-calculator";
import { Container } from "@/domains/_app";

export async function calculateGeeEmission(
  params: CalculateGeeEmissionInput
): Promise<CalculateGeeEmissionOutput> {
  return Container.EmissionCalculatorService.calculate(params);
}
