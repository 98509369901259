import { English } from "./english";
import { Portuguese } from "./portuguese";

export const resources = {
  en: {
    translation: English,
  },
  pt: {
    translation: Portuguese,
  },
};
