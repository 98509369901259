import {
  Button,
  DataTable,
  Divider,
  NumberField,
  PercentageField,
  TextArea,
} from "@/domains/_shared/components";
import { useTranslation } from "@/domains/_shared/hooks";
import { NumberFormatter } from "@/domains/_shared/formatters";
import {
  FertilizersTable,
  StepFormLayout,
  useFertilizersForm,
  OrganicFertilizerSelect,
} from "@/domains/calculate-emissions";

import * as Styles from "./Fertilizers.styles";

export function Fertilizers() {
  const { translate } = useTranslation("calculate_form.fertilizers");
  const { translate: translateOrganicFertilizer } = useTranslation(
    "constrains.organic_fertilizer"
  );

  const {
    totalArea,
    organicFertilizer,
    npkFertilizer,
    ureaFertilizer,
    limestoneFertilizer,

    list,
  } = useFertilizersForm();

  return (
    <StepFormLayout
      title={<h2>{translate("title")}</h2>}
      table={<FertilizersTable />}
      form={
        <>
          <NumberField
            name="total_area_amount_input"
            label={translate("fields.total_area.label")}
            value={totalArea.total}
            onChange={totalArea.updateTotalArea}
            error={totalArea.errors.total}
          />

          <TextArea
            name="culture_input"
            label={translate("fields.culture.label")}
            value={totalArea.culture}
            onChange={totalArea.updateCulture}
            error={totalArea.errors.culture}
          />

          <div>
            <h3>{translate("fields.organic_fertilizer.title")}</h3>

            <Styles.Form>
              <Styles.InputsContainer>
                <OrganicFertilizerSelect
                  name="organic_fertilizer_input"
                  value={organicFertilizer.fertilizer}
                  onChange={organicFertilizer.updateFertilizer}
                />

                <NumberField
                  name="organic_fertilizer_amount_input"
                  label={translate("fields.amount.label")}
                  value={organicFertilizer.amount}
                  onChange={organicFertilizer.updateAmount}
                  error={organicFertilizer.errors.amount}
                />
              </Styles.InputsContainer>

              <Styles.Actions>
                <DataTable
                  columns={[
                    translate("fields.organic_fertilizer.label"),
                    translate("fields.amount.label"),
                  ]}
                  isEmpty={organicFertilizer.isEmpty}
                  items={organicFertilizer.items.map((fertilizer) => [
                    translateOrganicFertilizer(fertilizer.fertilizer),
                    NumberFormatter.format(fertilizer.amount),
                  ])}
                  onRemove={organicFertilizer.remove}
                />

                <Styles.ButtonContainer onClick={organicFertilizer.add}>
                  <Button name="organic_fertilizer">
                    {translate("add_button.label")}
                  </Button>
                </Styles.ButtonContainer>
              </Styles.Actions>
            </Styles.Form>
          </div>

          <Divider />

          <div>
            <h3>{translate("fields.nitrogenous_fertilizer.title")}</h3>

            <Styles.Form>
              <Styles.InputsContainer>
                <NumberField
                  name="npk_fertilizer_amount_input"
                  label={translate("fields.amount.label")}
                  value={npkFertilizer.amount}
                  onChange={npkFertilizer.updateAmount}
                  error={npkFertilizer.errors.amount}
                />

                <PercentageField
                  name="npk_fertilizer_percentual_input"
                  label={translate("fields.nitrogenous_fertilizer.label")}
                  value={npkFertilizer.percentage}
                  onChange={npkFertilizer.updatePercentual}
                  error={npkFertilizer.errors.percentage}
                />
              </Styles.InputsContainer>

              <Styles.Actions>
                <DataTable
                  name="npk_table"
                  columns={[
                    translate("fields.amount.label"),
                    translate("fields.nitrogenous_fertilizer.label"),
                  ]}
                  isEmpty={npkFertilizer.isEmpty}
                  items={npkFertilizer.items.map((fertilizer) => [
                    NumberFormatter.format(fertilizer.amount),
                    fertilizer.percentage + "%",
                  ])}
                  onRemove={npkFertilizer.remove}
                />

                <Styles.ButtonContainer>
                  <Button name="npk_fertilizer" onClick={npkFertilizer.add}>
                    {translate("add_button.label")}
                  </Button>
                </Styles.ButtonContainer>
              </Styles.Actions>
            </Styles.Form>
          </div>

          <Divider />

          <h3>{translate("fields.urea.title")}</h3>

          <Styles.Form>
            <Styles.InputsContainer>
              <NumberField
                name="ureia_fertilizer_amount_input"
                label={translate("fields.amount.label")}
                value={ureaFertilizer.amount}
                onChange={ureaFertilizer.updateAmount}
                error={ureaFertilizer.errors.amount}
              />

              <PercentageField
                name="ureia_fertilizer_percentual_input"
                label={translate("fields.urea.label")}
                value={ureaFertilizer.percentage}
                onChange={ureaFertilizer.updatePercentual}
                error={ureaFertilizer.errors.percentage}
              />
            </Styles.InputsContainer>

            <Styles.Actions>
              <DataTable
                columns={[
                  translate("fields.amount.label"),
                  translate("fields.urea.label"),
                ]}
                isEmpty={ureaFertilizer.isEmpty}
                items={ureaFertilizer.items.map((fertilizer) => [
                  NumberFormatter.format(fertilizer.amount),
                  fertilizer.percentage + "%",
                ])}
                onRemove={ureaFertilizer.remove}
              />

              <Styles.ButtonContainer onClick={ureaFertilizer.add}>
                <Button name="ureia_fertilizer">
                  {translate("add_button.label")}
                </Button>
              </Styles.ButtonContainer>
            </Styles.Actions>
          </Styles.Form>

          <Divider />

          <h3>{translate("fields.limestone.title")}</h3>

          <Styles.Form>
            <Styles.LimestoneContainer>
              <NumberField
                name="limestone_fertilizer_amount_input"
                label={translate("fields.amount.label")}
                value={limestoneFertilizer.amount}
                onChange={limestoneFertilizer.updateAmount}
                error={limestoneFertilizer.errors.amount}
              />
            </Styles.LimestoneContainer>

            <Styles.Actions>
              <DataTable
                columns={[translate("fields.amount.label")]}
                isEmpty={limestoneFertilizer.isEmpty}
                items={limestoneFertilizer.items.map((fertilizer) => [
                  NumberFormatter.format(fertilizer.amount),
                ])}
                onRemove={limestoneFertilizer.remove}
              />

              <Styles.ButtonContainer onClick={limestoneFertilizer.add}>
                <Button name="limestone_fertilizer">
                  {translate("add_button.label")}
                </Button>
              </Styles.ButtonContainer>
            </Styles.Actions>
          </Styles.Form>

          <Divider />
        </>
      }
      button={
        <Button name="add_button" onClick={list.add}>
          {translate("add_button.label")}
        </Button>
      }
    />
  );
}
