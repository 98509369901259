import { useMutation } from "react-query";

import { calculateGeeEmission } from "@/domains/calculate-emissions";

export function useCalculateForm() {
  const { mutateAsync, isSuccess, data } = useMutation(calculateGeeEmission);

  return {
    calculate: mutateAsync,
    isSuccess,
    data,
  };
}
