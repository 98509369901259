export enum GasOrCompound {
  CO2 = "CO2",
  CH4 = "CH4",
  N2O = "N2O",
  HFC_23 = "HFC_23",
  HFC_32 = "HFC_32",
  HFC_41 = "HFC_41",
  HFC_125 = "HFC_125",
  HFC_134 = "HFC_134",
  HFC_134a = "HFC_134a",
  HFC_143 = "HFC_143",
  HFC_143a = "HFC_143a",
  HFC_152 = "HFC_152",
  HFC_152a = "HFC_152a",
  HFC_161 = "HFC_161",
  HFC_227ea = "HFC_227ea",
  HFC_236cb = "HFC_236cb",
  HFC_236ea = "HFC_236ea",
  HFC_236fa = "HFC_236fa",
  HFC_245ca = "HFC_245ca",
  HFC_245fa = "HFC_245fa",
  HFC_365mfc = "HFC_365mfc",
  HFC_43_10mee = "HFC_43_10mee",
  SF6 = "SF6",
  NF3 = "NF3",
  PFC_14 = "PFC_14",
  PFC_116 = "PFC_116",
  PFC_218 = "PFC_218",
  PFC_318 = "PFC_318",
  PFC_3_1_10 = "PFC_3_1_10",
  PFC_4_1_12 = "PFC_4_1_12",
  PFC_5_1_14 = "PFC_5_1_14",
  PFC_9_1_18 = "PFC_9_1_18",
  SF5CF3 = "SF5CF3",
  c_C3F6 = "c_C3F6",
  R_400 = "R_400",
  R_401A = "R_401A",
  R_401B = "R_401B",
  R_401C = "R_401C",
  R_402A = "R_402A",
  R_402B = "R_402B",
  R_403A = "R_403A",
  R_403B = "R_403B",
  R_404A = "R_404A",
  R_405A = "R_405A",
  R_406A = "R_406A",
  R_407A = "R_407A",
  R_407B = "R_407B",
  R_407C = "R_407C",
  R_407D = "R_407D",
  R_407E = "R_407E",
  R_407F = "R_407F",
  R_407G = "R_407G",
  R_407H = "R_407H",
  R_407I = "R_407I",
  R_408A = "R_408A",
  R_409A = "R_409A",
  R_409B = "R_409B",
  R_410A = "R_410A",
  R_410B = "R_410B",
  R_411A = "R_411A",
  R_411B = "R_411B",
  R_412A = "R_412A",
  R_413A = "R_413A",
  R_414A = "R_414A",
  R_414B = "R_414B",
  R_415A = "R_415A",
  R_415B = "R_415B",
  R_416A = "R_416A",
  R_417A = "R_417A",
  R_417B = "R_417B",
  R_417C = "R_417C",
  R_418A = "R_418A",
  R_419A = "R_419A",
  R_419B = "R_419B",
  R_420A = "R_420A",
  R_421A = "R_421A",
  R_421B = "R_421B",
  R_422A = "R_422A",
  R_422B = "R_422B",
  R_422C = "R_422C",
  R_422D = "R_422D",
  R_422E = "R_422E",
  R_423A = "R_423A",
  R_424A = "R_424A",
  R_425A = "R_425A",
  R_426A = "R_426A",
  R_427A = "R_427A",
  R_428A = "R_428A",
  R_429A = "R_429A",
  R_430A = "R_430A",
  R_431A = "R_431A",
  R_432A = "R_432A",
  R_433A = "R_433A",
  R_433B = "R_433B",
  R_433C = "R_433C",
  R_434A = "R_434A",
  R_435A = "R_435A",
  R_436A = "R_436A",
  R_436B = "R_436B",
  R_436C = "R_436C",
  R_437A = "R_437A",
  R_438A = "R_438A",
  R_439A = "R_439A",
  R_440A = "R_440A",
  R_441A = "R_441A",
  R_442A = "R_442A",
  R_443A = "R_443A",
  R_444A = "R_444A",
  R_444B = "R_444B",
  R_445A = "R_445A",
  R_446A = "R_446A",
  R_447A = "R_447A",
  R_447B = "R_447B",
  R_448A = "R_448A",
  R_449A = "R_449A",
  R_449B = "R_449B",
  R_449C = "R_449C",
  R_450A = "R_450A",
  R_451A = "R_451A",
  R_451B = "R_451B",
  R_452A = "R_452A",
  R_452B = "R_452B",
  R_452C = "R_452C",
  R_453A = "R_453A",
  R_454A = "R_454A",
  R_454B = "R_454B",
  R_454C = "R_454C",
  R_455A = "R_455A",
  R_456A = "R_456A",
  R_457A = "R_457A",
  R_458A = "R_458A",
  R_459A = "R_459A",
  R_459B = "R_459B",
  R_460A = "R_460A",
  R_460B = "R_460B",
  R_460C = "R_460C",
  R_461A = "R_461A",
  R_462A = "R_462A",
  R_463A = "R_463A",
  R_464A = "R_464A",
  R_465A = "R_465A",
  R_500 = "R_500",
  R_501 = "R_501",
  R_502 = "R_502",
  R_503 = "R_503",
  R_504 = "R_504",
  R_505 = "R_505",
  R_506 = "R_506",
  R_507orR_507A = "R_507orR_507A",
  R_508A = "R_508A",
  R_508B = "R_508B",
  R_509orR_509A = "R_509orR_509A",
  R_510A = "R_510A",
  R_511A = "R_511A",
  R_512A = "R_512A",
  R_513A = "R_513A",
  R_513B = "R_513B",
  R_514A = "R_514A",
  R_515A = "R_515A",
  R_516A = "R_516A",
}
