import {
  PoultryFarmingTable,
  StepFormLayout,
  usePoultryFarmingForm,
} from "@/domains/calculate-emissions";
import { useTranslation } from "@/domains/_shared/hooks";
import { Button } from "@/domains/_shared/components";

import { Form } from "./PoultryFarming.form";

export function PoultryFarming() {
  const { translate } = useTranslation("calculate_form.poultryfarming");

  const { addFarmingToList } = usePoultryFarmingForm();

  return (
    <StepFormLayout
      title={translate("title")}
      table={<PoultryFarmingTable />}
      form={
        <>
          <h3>{translate("subtitle")}</h3>

          <Form />
        </>
      }
      button={
        <Button name="add_button" onClick={addFarmingToList}>
          {translate("fields.button.label")}
        </Button>
      }
    />
  );
}
