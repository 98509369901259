import styled from "styled-components";

import { Field } from "@/domains/_shared/models";

export const Input = styled.input<Field>`
  cursor: pointer;
  font-size: ${(props) => props.theme.components.shared.form.font_size};

  outline: none;
  background: ${(props) => props.theme.colors.background_light};
`;
