import styled from "styled-components";

import { Grid } from "@/domains/_shared/components";

export const Form = styled.div`
  gap: 10px;
`;

export const InputsContainer = styled(Grid)`
  width: 100%;
  grid-template-columns: 1fr 1fr;
`;

export const LimestoneContainer = styled(Grid)`
  width: 100%;
  grid-template-columns: 1fr;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    margin-left: 10px;
  }

  table {
    margin-top: 0;
  }
`;
