import { Button, Step, Stepper, Variants } from "@/domains/_shared/components";
import {
  Overview,
  StationaryCombustion,
  MobileCombustion,
  FugitiveEmissions,
  Fertilizers,
  Steps,
  useStepper,
  PigFarming,
  PoultryFarming,
} from "@/domains/calculate-emissions";
import { FormProvider } from "@/domains/calculate-emissions/context";
import { useTranslation } from "@/domains/_shared/hooks";

import * as Styles from "./CalculateForm.styles";

export function CalculateForm() {
  const { translate } = useTranslation("calculate_form.stepper");
  const { current, goToNext, goBack, isFirstStep, isLastStep } = useStepper();

  return (
    <Styles.Container>
      <Styles.Content>
        <FormProvider>
          <Stepper current={current}>
            <Step step={Steps.STATIONARY_COMBUSTION}>
              <StationaryCombustion />
            </Step>

            <Step step={Steps.MOBILE_COMBUSTION}>
              <MobileCombustion />
            </Step>

            <Step step={Steps.FUGITIVE_EMISSIONS}>
              <FugitiveEmissions />
            </Step>

            <Step step={Steps.FERTILIZERS}>
              <Fertilizers />
            </Step>

            <Step step={Steps.PIG_FARMING}>
              <PigFarming />
            </Step>

            <Step step={Steps.POULTRY_FARMING}>
              <PoultryFarming />
            </Step>

            <Step step={Steps.OVERVIEW}>
              <Overview />
            </Step>
          </Stepper>
        </FormProvider>

        <Styles.FullRight>
          <Button
            name="previous_button"
            variant={Variants.secondary}
            onClick={goBack}
            disabled={isFirstStep}
          >
            {translate("previous_button.label")}
          </Button>

          <Button name="next_button" onClick={goToNext} disabled={isLastStep}>
            {translate("next_button.label")}
          </Button>
        </Styles.FullRight>
      </Styles.Content>
    </Styles.Container>
  );
}
