import { OrganicFertilizers } from "@/domains/calculate-emissions/constrains";
import { Validator } from "@/domains/_shared/validators";
import {
  useArrayState,
  useFormBuilder,
  useTranslation,
} from "@/domains/_shared/hooks";
import {
  OrganicFertilizer,
  NpkFertilizer,
  LimestoneFertilizer,
  UreaFertilizer,
  FertilizerForm,
  Fertilizers,
} from "@/domains/calculate-emissions/context";

import { translateErrors, useStepFormBuilder } from "./useStepFormBuilder";

export function useFertilizers(): FertilizerForm {
  const { translate } = useTranslation("calculate_form.fertilizers.fields");
  const list = useArrayState<Fertilizers>();

  const area = useFormBuilder<{
    total: string;
    culture: string;
  }>({
    total: "0,00",
    culture: "",
  });

  const organicFertilizer = useStepFormBuilder<OrganicFertilizer>({
    translationKey: "calculate_form.fertilizers.fields",
    defaultValues: {
      fertilizer: OrganicFertilizers.Manure,
      amount: "0,00",
    },
    validator: new Validator<OrganicFertilizer>({
      amount: ["above_zero"],
    }),
  });

  const fertilizerNpk = useStepFormBuilder<NpkFertilizer>({
    translationKey: "calculate_form.fertilizers.fields",
    defaultValues: {
      amount: "0,00",
      percentage: "0",
    },
    validator: new Validator({
      amount: ["above_zero"],
      percentage: ["above_zero"],
    }),
  });

  const fertilizerUreia = useStepFormBuilder<UreaFertilizer>({
    translationKey: "calculate_form.fertilizers.fields",
    defaultValues: {
      amount: "0,00",
      percentage: "0",
    },
    validator: new Validator({
      amount: ["above_zero"],
      percentage: ["above_zero"],
    }),
  });

  const fertilizerLimestone = useStepFormBuilder<LimestoneFertilizer>({
    translationKey: "calculate_form.fertilizers.fields",
    defaultValues: {
      amount: "0,00",
    },
    validator: new Validator({
      amount: ["above_zero"],
    }),
  });

  const add = () => {
    area.resetErrors();

    const validator = new Validator({
      total: ["above_zero"],
      culture: ["required"],
    });

    const { isValid, errors } = validator.validate(area);

    if (isValid) {
      list.addItem({
        totalArea: area.total,
        culture: area.culture,
        organicFertilizer: organicFertilizer.array,
        npkFertilizer: fertilizerNpk.array,
        ureaFertilizer: fertilizerUreia.array,
        limestoneFertilizer: fertilizerLimestone.array,
      });

      return;
    }

    area.updateErrors(translateErrors(translate)(errors));
  };

  return {
    list: {
      add,
      items: list.array,
      isEmpty: list.isEmpty,
      remove: list.removeItem,
    },

    totalArea: {
      total: area.total,
      culture: area.culture,
      updateCulture: area.updateValueCurry("culture"),
      updateTotalArea: area.updateValueCurry("total"),

      errors: area.errors,
    },

    organicFertilizer: {
      fertilizer: organicFertilizer.fertilizer,
      amount: organicFertilizer.amount,
      errors: organicFertilizer.errors,

      add: organicFertilizer.addItemToArray,
      items: organicFertilizer.array,
      remove: organicFertilizer.removeItem,
      isEmpty: organicFertilizer.isEmpty,

      updateFertilizer: organicFertilizer.updateValueCurry("fertilizer"),
      updateAmount: organicFertilizer.updateValueCurry("amount"),
    },
    npkFertilizer: {
      amount: fertilizerNpk.amount,
      percentage: fertilizerNpk.percentage,
      errors: fertilizerNpk.errors,

      add: fertilizerNpk.addItemToArray,
      items: fertilizerNpk.array,
      remove: fertilizerNpk.removeItem,
      isEmpty: fertilizerNpk.isEmpty,

      updateAmount: fertilizerNpk.updateValueCurry("amount"),
      updatePercentual: fertilizerNpk.updateValueCurry("percentage"),
    },
    ureaFertilizer: {
      amount: fertilizerUreia.amount,
      percentage: fertilizerUreia.percentage,
      errors: fertilizerUreia.errors,

      add: fertilizerUreia.addItemToArray,
      items: fertilizerUreia.array,
      remove: fertilizerUreia.removeItem,
      isEmpty: fertilizerUreia.isEmpty,

      updateAmount: fertilizerUreia.updateValueCurry("amount"),
      updatePercentual: fertilizerUreia.updateValueCurry("percentage"),
    },
    limestoneFertilizer: {
      amount: fertilizerLimestone.amount,
      errors: fertilizerLimestone.errors,

      add: fertilizerLimestone.addItemToArray,
      items: fertilizerLimestone.array,
      remove: fertilizerLimestone.removeItem,
      isEmpty: fertilizerLimestone.isEmpty,

      updateAmount: fertilizerLimestone.updateValueCurry("amount"),
    },
  };
}
