import { useTranslation } from "@/domains/_shared/hooks";
import { NumberFormatter } from "@/domains/_shared/formatters";
import { DataTable } from "@/domains/_shared/components";

import { useMobileCombustionForm, useTranslateFuel } from "../hooks";

export function MobileCombustionTable() {
  const { translate } = useTranslation(
    "calculate_form.mobile_combustion.table"
  );
  const { translateFuel } = useTranslateFuel();

  const { mobileCombustionList } = useMobileCombustionForm();

  return (
    <DataTable
      title={translate("title", { size: mobileCombustionList.size })}
      columns={[
        translate("columns.description"),
        translate("columns.fuel"),
        translate("columns.amount"),
      ]}
      empty_title={translate("empty.title")}
      empty_subtitle={translate("empty.subtitle")}
      isEmpty={mobileCombustionList.isEmpty}
      onRemove={mobileCombustionList.remove}
      items={mobileCombustionList.items.map((item) => {
        return [
          item.description,
          translateFuel(item.fuel),
          NumberFormatter.format(item.amount),
        ];
      })}
    />
  );
}
