import { Navigate, Route } from "react-router-dom";

import { AppRoutes } from "@/domains/_shared/constrains";
import { useAuth } from "@/domains/_shared/hooks";
import { CalculateForm } from "@/domains/calculate-emissions";
import { Login } from "@/domains/authentication";

import { RoutingContainer } from "./RoutingContainer";
import { PrivateRoute } from "./PrivateRoute";

export function Routes() {
  const { isAuthenticated } = useAuth();

  return (
    <RoutingContainer>
      <Route
        path="*"
        element={
          <Navigate
            to={isAuthenticated ? AppRoutes.home() : AppRoutes.login()}
          />
        }
      />

      <Route path={AppRoutes.login()} element={<Login />} />
      <Route
        path={AppRoutes.home()}
        element={<Navigate to={AppRoutes.calculate()} />}
      />
      <Route
        path={AppRoutes.calculate()}
        element={
          <PrivateRoute isAuthenticated={isAuthenticated}>
            <CalculateForm />
          </PrivateRoute>
        }
      />
    </RoutingContainer>
  );
}
