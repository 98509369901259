import React from "react";

import { ExternalNumberField } from "../ExternalNumberField";

import { NumberFieldProps } from "./NumberField.types";

export const NumberField: React.FC<NumberFieldProps> = (props) => {
  return (
    <ExternalNumberField
      {...props}
      allowDecimals
      allowNegativeValue={false}
      decimalsLimit={2}
      decimalSeparator=","
      groupSeparator="."
    />
  );
};
