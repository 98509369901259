import { useTranslation } from "@/domains/_shared/hooks";
import { Button, TextArea } from "@/domains/_shared/components";
import {
  MobileFuelSelect,
  MobileCombustionTable,
  StepFormLayout,
  FuelAmount,
  useMobileCombustionForm,
} from "@/domains/calculate-emissions";

import * as Styles from "./MobileCombustion.styles";

export function MobileCombustion() {
  const { translate } = useTranslation("calculate_form.mobile_combustion");

  const {
    description,
    updateDescription,
    fuel,
    updateFuel,
    amount,
    updateAmount,
    errors,
    addMobileCombustion,
  } = useMobileCombustionForm();

  return (
    <StepFormLayout
      title={<h2>{translate("title")}</h2>}
      table={<MobileCombustionTable />}
      form={
        <>
          <TextArea
            name="description_input"
            label={translate("fields.description.label")}
            value={description}
            error={errors.description}
            onChange={updateDescription}
          />

          <Styles.Split>
            <MobileFuelSelect
              label={translate("fields.fuel.label")}
              fuel={fuel}
              onChange={updateFuel}
            />

            <FuelAmount
              fuel={fuel}
              value={amount}
              onChange={updateAmount}
              error={errors.amount}
            />
          </Styles.Split>
        </>
      }
      button={
        <Button name="add_button" onClick={addMobileCombustion}>
          {translate("button.label")}
        </Button>
      }
    />
  );
}
