import { HTMLProps } from "react";

type HtmlButtonProps = HTMLProps<HTMLButtonElement>;

export interface ButtonProps extends HtmlButtonProps {
  name?: string;
  variant?: Variants;
}

export enum Variants {
  primary = "primary",
  secondary = "secondary",
}
