import {
  useArrayState,
  useFormBuilder,
  useTranslation,
} from "@/domains/_shared/hooks";
import { Validator } from "@/domains/_shared/validators";

type Input<T> = {
  defaultValues: T;
  validator: Validator<T>;
  translationKey: string;
};

export const translateErrors =
  (translate: Function) =>
  <T>(errors: { [key: string]: string }) => {
    const result: { [key: string]: string } = {};

    for (const error in errors) {
      if (errors.hasOwnProperty(error)) {
        result[error] =
          errors[error] === "" ? "" : translate(errors[error] as string);
      }
    }

    return result as Partial<T>;
  };

export function useStepFormBuilder<T>(input: Input<T>) {
  const { translate } = useTranslation(input.translationKey);
  const formBuilder = useFormBuilder<T>(input.defaultValues);
  const { addItem, ...arrayStateValues } = useArrayState<T>();

  const addItemToArray = () => {
    if (!validate()) return;

    addItem(formBuilder);
    formBuilder.resetErrors();
  };

  const validate = () => {
    const { isValid, errors } = input.validator.validate(formBuilder);

    formBuilder.updateErrors(translateErrors(translate)<T>(errors));
    return isValid;
  };

  return { ...formBuilder, ...arrayStateValues, addItemToArray };
}
