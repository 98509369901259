import {
  MetricUnit,
  AnyFuel,
  MobileCombustionFuels,
  StationaryCombustionFuels,
} from "@/domains/calculate-emissions/constrains";
import { NumberField, TextFieldProps } from "@/domains/_shared/components";
import { useTranslation } from "@/domains/_shared/hooks";

interface FuelAmountProps extends TextFieldProps {
  fuel: AnyFuel;
}

export function FuelAmount(props: FuelAmountProps) {
  const { translate: translateUnit } = useTranslation("constrains.metricUnit");
  const { translate: translateLabel } = useTranslation(
    "calculate_form.stationary_combustion.fields.amount"
  );

  const unit = getUnit(props.fuel);
  const label = translateLabel("label", { unit: translateUnit(unit) });

  return <NumberField name="amount_input" label={label} {...props} />;
}

function getUnit(fuel: AnyFuel) {
  const fuels: Record<AnyFuel, MetricUnit> = {
    [StationaryCombustionFuels.Acetylene]: MetricUnit.kg,
    [StationaryCombustionFuels.Tar]: MetricUnit.m3,
    [StationaryCombustionFuels.Asphalts]: MetricUnit.m3,
    [StationaryCombustionFuels.BagasseOfCane]: MetricUnit.tons,
    [StationaryCombustionFuels.BiodieselB100]: MetricUnit.liters,
    [StationaryCombustionFuels.BiogasOther]: MetricUnit.tons,
    [StationaryCombustionFuels.BiogasLandfill]: MetricUnit.tons,
    [StationaryCombustionFuels.Biomethane]: MetricUnit.tons,
    [StationaryCombustionFuels.SugarcaneJuice]: MetricUnit.tons,
    [StationaryCombustionFuels.ImportedMetallurgicalCoal]: MetricUnit.tons,
    [StationaryCombustionFuels.DomesticMetallurgicalCoal]: MetricUnit.tons,
    [StationaryCombustionFuels.SteamCoal3100KcalKg]: MetricUnit.tons,
    [StationaryCombustionFuels.SteamCoal3300KcalKg]: MetricUnit.tons,
    [StationaryCombustionFuels.SteamCoal3700KcalKg]: MetricUnit.tons,
    [StationaryCombustionFuels.SteamCoal4200KcalKg]: MetricUnit.tons,
    [StationaryCombustionFuels.SteamCoal4500KcalKg]: MetricUnit.tons,
    [StationaryCombustionFuels.SteamCoal4700KcalKg]: MetricUnit.tons,
    [StationaryCombustionFuels.SteamCoal5200KcalKg]: MetricUnit.tons,
    [StationaryCombustionFuels.SteamCoal5900KcalKg]: MetricUnit.tons,
    [StationaryCombustionFuels.SteamCoal6000KcalKg]: MetricUnit.tons,
    [StationaryCombustionFuels.UnspecifiedSteamCoal]: MetricUnit.tons,
    [StationaryCombustionFuels.Charcoal]: MetricUnit.tons,
    [StationaryCombustionFuels.CoalCoke]: MetricUnit.tons,
    [StationaryCombustionFuels.PetroleumCoke]: MetricUnit.m3,
    [StationaryCombustionFuels.Ethane]: MetricUnit.tons,
    [StationaryCombustionFuels.Ethanol]: MetricUnit.liters,
    [StationaryCombustionFuels.CokingGas]: MetricUnit.tons,
    [StationaryCombustionFuels.RefineryGas]: MetricUnit.tons,
    [StationaryCombustionFuels.LiquefiedPetroleumGasGLP]: MetricUnit.tons,
    [StationaryCombustionFuels.DryNaturalGas]: MetricUnit.m3,
    [StationaryCombustionFuels.WetNaturalGas]: MetricUnit.m3,
    [StationaryCombustionFuels.AutomotiveGasolineCommercial]: MetricUnit.liters,
    [StationaryCombustionFuels.AutomotiveGasolinePure]: MetricUnit.liters,
    [StationaryCombustionFuels.AviationGasoline]: MetricUnit.liters,
    [StationaryCombustionFuels.CommercialFirewood]: MetricUnit.tons,
    [StationaryCombustionFuels.BlackLiquor]: MetricUnit.tons,
    [StationaryCombustionFuels.LiquefiedNaturalGasLGN]: MetricUnit.tons,
    [StationaryCombustionFuels.Lubricants]: MetricUnit.liters,
    [StationaryCombustionFuels.Molasses]: MetricUnit.tons,
    [StationaryCombustionFuels.Naphtha]: MetricUnit.m3,
    [StationaryCombustionFuels.FuelOil]: MetricUnit.liters,
    [StationaryCombustionFuels.ShaleOil]: MetricUnit.tons,
    [StationaryCombustionFuels.DieselOilCommercial]: MetricUnit.liters,
    [StationaryCombustionFuels.DieselOilPure]: MetricUnit.liters,
    [StationaryCombustionFuels.ResidualOils]: MetricUnit.tons,
    [StationaryCombustionFuels.OtherPetroleumProducts]: MetricUnit.tons,
    [StationaryCombustionFuels.Paraffin]: MetricUnit.tons,
    [StationaryCombustionFuels.CrudeOil]: MetricUnit.m3,
    [StationaryCombustionFuels.AviationKerosene]: MetricUnit.tons,
    [StationaryCombustionFuels.IlluminatingKerosene]: MetricUnit.tons,
    [StationaryCombustionFuels.IndustrialWaste]: MetricUnit.tj,
    [StationaryCombustionFuels.MunicipalWasteBiomassFraction]: MetricUnit.tons,
    [StationaryCombustionFuels.MunicipalWasteNonBiomassFraction]:
      MetricUnit.tons,
    [StationaryCombustionFuels.VegetableWaste]: MetricUnit.tons,
    [StationaryCombustionFuels.Solvents]: MetricUnit.liters,
    [StationaryCombustionFuels.Peat]: MetricUnit.tons,
    [StationaryCombustionFuels.OilShaleAndTarSands]: MetricUnit.tons,

    [MobileCombustionFuels.BiodieselB100]: MetricUnit.liters,
    [MobileCombustionFuels.Ethanol]: MetricUnit.liters,
    [MobileCombustionFuels.LiquefiedPetroleumGasGLP]: MetricUnit.kg,
    [MobileCombustionFuels.VehicleNaturalGasGNV]: MetricUnit.m3,
    [MobileCombustionFuels.AutomotiveGasolineCommercial]: MetricUnit.liters,
    [MobileCombustionFuels.DieselOilCommercial]: MetricUnit.liters,
  };

  return fuels[fuel] || "";
}
