import styled from "styled-components";

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.grey_100};
  min-height: 100vh;

  display: flex;
  justify-content: center;
  align-content: center;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1200px;
  height: fit-content;

  padding: 16px;
  border-radius: 2px;
  margin: 14px;

  border: 1px solid ${({ theme }) => theme.colors.grey_300};
  border-top: 4px solid ${({ theme }) => theme.colors.text};

  background-color: ${({ theme }) => theme.colors.background_light};
`;

export const FullRight = styled.div`
  display: flex;
  justify-content: flex-end;

  gap: 10px;
`;
