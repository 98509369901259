import React from "react";
import { BrowserRouter, Routes } from "react-router-dom";

import { Header } from "@/domains/_shared/components";

export const RoutingContainer: React.ProviderFC = ({ children }) => (
  <BrowserRouter>
    <Header />

    <Routes>{children}</Routes>
  </BrowserRouter>
);
