export class TokenStorage {
  private readonly key = "@/auth/token";

  get token() {
    return localStorage.getItem(this.key);
  }

  setToken(token: string) {
    localStorage.setItem(this.key, token);
  }
}
