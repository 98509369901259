import React from "react";

import { useTranslation } from "@/domains/_shared/hooks";
import { TextArea, Button, Divider } from "@/domains/_shared/components";
import {
  StationaryCombustionTable,
  FuelAmount,
  StationaryFuelSelect,
  useStationaryCombustionForm,
  StationarySectorSelect,
} from "@/domains/calculate-emissions";

import * as Styles from "./StationaryCombustion.styles";

export function StationaryCombustion() {
  const { translate } = useTranslation("calculate_form.stationary_combustion");

  const {
    description,
    fuel,
    amount,
    updateDescription,
    updateFuel,
    updateAmount,

    errors,

    addStationaryCombustion,
  } = useStationaryCombustionForm();

  return (
    <Styles.Container>
      <h2>{translate("title")}</h2>

      <StationarySectorSelect />

      <Divider />

      <Styles.Spacer>
        <StationaryCombustionTable />
      </Styles.Spacer>

      <Divider />

      <h3>{translate("subtitle")}</h3>

      <TextArea
        name="description_input"
        label={translate("fields.description.label")}
        value={description}
        onChange={updateDescription}
        error={errors.description}
      />

      <Styles.Split>
        <StationaryFuelSelect
          label={translate("fields.fuel.label")}
          fuel={fuel}
          updateFuel={updateFuel}
        />

        <FuelAmount
          fuel={fuel}
          value={amount}
          onChange={updateAmount}
          error={errors.amount}
        />
      </Styles.Split>

      <Styles.FullRight>
        <Button name="add_button" onClick={addStationaryCombustion}>
          {translate("button.label")}
        </Button>
      </Styles.FullRight>

      <Divider />
    </Styles.Container>
  );
}
