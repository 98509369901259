import { Pipeline } from "@/domains/_shared/models";
import { RequiredDateValidator } from "@/domains/_shared/validators/required-date-validator";

import {
  ValidationErrors,
  ValidationSchema,
  RuleValidator,
  ValidationRule,
} from "./validator.types";
import { RequiredValidator } from "./required-validator";
import { AboveZeroValidator } from "./above-zero-validator";

export class Validator<T> {
  private readonly schema: Partial<ValidationSchema<T>>;
  private readonly ruleValidators: Record<
    ValidationRule,
    RuleValidator<T[keyof T]>
  >;

  constructor(schema: Partial<ValidationSchema<T>>) {
    this.schema = schema;
    this.ruleValidators = {
      required: new RequiredValidator<T[keyof T]>(),
      above_zero: new AboveZeroValidator<T[keyof T]>(),
      required_date: new RequiredDateValidator<T[keyof T]>(),
    };
  }

  public validate(data: Record<string, any>): {
    isValid: boolean;
    errors: ValidationErrors<T>;
  } {
    const errors: Partial<ValidationErrors<T>> = {};
    let isValid = true;

    Object.keys(this.schema).forEach((field) => {
      this.schema[field as keyof T]?.forEach((rule: ValidationRule) => {
        const ruleValidator = this.ruleValidators[rule];
        errors[field as keyof T] = "";

        if (ruleValidator.isNotValid(data[field])) {
          errors[field as keyof T] = ruleValidator.getError(
            this.formatField(field)
          );
          isValid = false;
        }
      });
    });

    return { isValid, errors: errors as ValidationErrors<T> };
  }

  private formatField(field: string) {
    return new Pipeline(field)
      .run((value) => value.replace(/([a-z])([A-Z])/g, "$1_$2"))
      .run((value) => value.toLowerCase())
      .finish();
  }
}
