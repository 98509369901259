import { useStepFormBuilder } from "@/domains/calculate-emissions";
import { Validator } from "@/domains/_shared/validators";
import {
  PigFarming,
  PigFarmingForm,
} from "@/domains/calculate-emissions/context";
import { PigFarmingTreatments } from "@/domains/calculate-emissions/constrains";

export function usePigFarming(): PigFarmingForm {
  const { updateValueCurry, ...values } = useStepFormBuilder<PigFarming>({
    translationKey: "calculate_form.pigfarming.fields",
    defaultValues: {
      id: "",
      treatment: PigFarmingTreatments.UncoveredAnaerobicLagoon,
      slaughterDate: new Date(),
      numberHousedAnimals: "0",
      numberSlaughteredAnimals: "0",

      averageWeightHousedAnimals: "0,00",
      totalWeightHousedAnimals: "0,00",
      totalWeightSlaughteredAnimals: "0,00",

      weightGain: "0,00",
      age: "0",
    },
    validator: new Validator<PigFarming>({
      id: ["required"],
      slaughterDate: ["required_date"],

      numberHousedAnimals: ["above_zero"],
      numberSlaughteredAnimals: ["above_zero"],

      averageWeightHousedAnimals: ["above_zero"],
      totalWeightHousedAnimals: ["above_zero"],
      totalWeightSlaughteredAnimals: ["above_zero"],

      weightGain: ["above_zero"],
      age: ["above_zero"],
    }),
  });

  return {
    ...values,

    addFarmingToList: values.addItemToArray,
    pigFarmingList: {
      items: values.array,
      isEmpty: values.isEmpty,
      size: values.size,
      remove: values.removeItem,
    },

    updateId: updateValueCurry("id"),
    updateTreatment: updateValueCurry("treatment"),
    updateSlaughterDate: (date: Date) =>
      values.updateValue("slaughterDate", date),

    updateNumberHousedAnimals: updateValueCurry("numberHousedAnimals"),
    updateNumberSlaughteredAnimals: updateValueCurry(
      "numberSlaughteredAnimals"
    ),

    updateAverageWeightHousedAnimals: updateValueCurry(
      "averageWeightHousedAnimals"
    ),
    updateTotalWeightHousedAnimals: updateValueCurry(
      "totalWeightHousedAnimals"
    ),
    updateTotalWeightSlaughteredAnimals: updateValueCurry(
      "totalWeightSlaughteredAnimals"
    ),

    updateWeightGain: updateValueCurry("weightGain"),
    updateAge: updateValueCurry("age"),
  };
}
