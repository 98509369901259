import { ReactElement, ReactNode } from "react";
import { Navigate } from "react-router-dom";

import { AppRoutes } from "@/domains/_shared/constrains";

interface PrivateRouteProps {
  isAuthenticated: boolean;
  children: ReactNode;
}

export function PrivateRoute({
  isAuthenticated,
  children,
}: PrivateRouteProps): ReactElement {
  if (isAuthenticated) return children as ReactElement;

  return <Navigate to={AppRoutes.login()} replace />;
}
