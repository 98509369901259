export enum StationaryCombustionFuels {
  Acetylene = "acetylene",
  Tar = "tar",
  Asphalts = "asphalts",
  BagasseOfCane = "bagasse_of_cane",
  BiodieselB100 = "biodiesel_b100",
  BiogasOther = "biogas_other",
  BiogasLandfill = "biogas_landfill",
  Biomethane = "biomethane",
  SugarcaneJuice = "sugarcane_juice",
  ImportedMetallurgicalCoal = "imported_metallurgical_coal",
  DomesticMetallurgicalCoal = "domestic_metallurgical_coal",
  SteamCoal3100KcalKg = "steam_coal_3100_kcal_kg",
  SteamCoal3300KcalKg = "steam_coal_3300_kcal_kg",
  SteamCoal3700KcalKg = "steam_coal_3700_kcal_kg",
  SteamCoal4200KcalKg = "steam_coal_4200_kcal_kg",
  SteamCoal4500KcalKg = "steam_coal_4500_kcal_kg",
  SteamCoal4700KcalKg = "steam_coal_4700_kcal_kg",
  SteamCoal5200KcalKg = "steam_coal_5200_kcal_kg",
  SteamCoal5900KcalKg = "steam_coal_5900_kcal_kg",
  SteamCoal6000KcalKg = "steam_coal_6000_kcal_kg",
  UnspecifiedSteamCoal = "unspecified_steam_coal",
  Charcoal = "charcoal",
  CoalCoke = "coal_coke",
  PetroleumCoke = "petroleum_coke",
  Ethane = "ethane",
  Ethanol = "ethanol",
  CokingGas = "coking_gas",
  RefineryGas = "refinery_gas",
  LiquefiedPetroleumGasGLP = "liquefied_petroleum_gas_glp",
  DryNaturalGas = "dry_natural_gas",
  WetNaturalGas = "wet_natural_gas",
  AutomotiveGasolineCommercial = "automotive_gasoline_commercial",
  AutomotiveGasolinePure = "automotive_gasoline_pure",
  AviationGasoline = "aviation_gasoline",
  CommercialFirewood = "commercial_firewood",
  BlackLiquor = "black_liquor",
  LiquefiedNaturalGasLGN = "liquefied_natural_gas_lgn",
  Lubricants = "lubricants",
  Molasses = "molasses",
  Naphtha = "naphtha",
  FuelOil = "fuel_oil",
  ShaleOil = "shale_oil",
  DieselOilCommercial = "diesel_oil_commercial",
  DieselOilPure = "diesel_oil_pure",
  ResidualOils = "residual_oils",
  OtherPetroleumProducts = "other_petroleum_products",
  Paraffin = "paraffin",
  CrudeOil = "crude_oil",
  AviationKerosene = "aviation_kerosene",
  IlluminatingKerosene = "illuminating_kerosene",
  IndustrialWaste = "industrial_waste",
  MunicipalWasteBiomassFraction = "municipal_waste_biomass_fraction",
  MunicipalWasteNonBiomassFraction = "municipal_waste_non_biomass_fraction",
  VegetableWaste = "vegetable_waste",
  Solvents = "solvents",
  Peat = "peat",
  OilShaleAndTarSands = "oil_shale_and_tar_sands",
}
