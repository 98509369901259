import { HttpClient, HttpMethods, HttpStatusCodes } from "@/domains/_app/http";
import { TokenStorage } from "@/domains/authentication/services";

export class AuthService {
  constructor(
    private readonly client: HttpClient,
    private readonly token: TokenStorage
  ) {}

  async login(password: string) {
    const response = await this.client.request<{ token: string }>({
      url: "/auth",
      method: HttpMethods.post,
      body: { password },
    });

    switch (response.statusCode) {
      case HttpStatusCodes.ok:
        this.token.setToken(response.body.token);
        return true;
      case HttpStatusCodes.unauthorized:
        return false;
      default:
        throw new Error();
    }
  }
}
