import { DataTable } from "@/domains/_shared/components";
import { useTranslation } from "@/domains/_shared/hooks";
import { NumberFormatter } from "@/domains/_shared/formatters";

import { useStationaryCombustionForm, useTranslateFuel } from "../hooks";

export function StationaryCombustionTable() {
  const { translate } = useTranslation(
    "calculate_form.stationary_combustion.table"
  );
  const { translateFuel } = useTranslateFuel();

  const { stationaryCombustionList } = useStationaryCombustionForm();

  return (
    <DataTable
      title={translate("title", { size: stationaryCombustionList.size })}
      columns={[
        translate("columns.description"),
        translate("columns.fuel"),
        translate("columns.amount"),
      ]}
      empty_title={translate("empty.title")}
      empty_subtitle={translate("empty.subtitle")}
      isEmpty={stationaryCombustionList.isEmpty}
      onRemove={stationaryCombustionList.remove}
      items={stationaryCombustionList.items.map((item) => {
        return [
          item.description,
          translateFuel(item.fuel),
          NumberFormatter.format(item.amount),
        ];
      })}
    />
  );
}
