import { useTranslation } from "@/domains/_shared/hooks";
import {
  DateField,
  IntegerNumberField,
  NumberField,
  TextArea,
} from "@/domains/_shared/components";
import { usePoultryFarmingForm } from "@/domains/calculate-emissions";

import * as Styles from "./PoultryFarming.styles";

export function Form() {
  const { translate } = useTranslation("calculate_form.poultryfarming.fields");

  const {
    id,
    updateId,

    slaughterDate,
    updateSlaughterDate,
    age,
    updateAge,

    numberHousedAnimals,
    updateNumberHousedAnimals,
    numberSlaughteredAnimals,
    updateNumberSlaughteredAnimals,
    numberDeadAnimals,
    updateNumberDeadAnimals,

    averageWeightHousedAnimals,
    updateAverageWeightHousedAnimals,
    totalWeightSlaughteredAnimals,
    updateTotalWeightSlaughteredAnimals,

    weightGainAnimal,
    updateWeightGainAnimal,
    weightGain,
    updateWeightGain,

    errors,
  } = usePoultryFarmingForm();

  return (
    <>
      <TextArea
        name="id_input"
        label={translate("id.label")}
        value={id}
        onChange={updateId}
        error={errors.id}
      />

      <Styles.TwoColumns>
        <DateField
          name="date_input"
          label={translate("slaughter_date.label")}
          blockFutureDates
          value={slaughterDate}
          onChange={updateSlaughterDate}
          error={errors.slaughterDate}
        />

        <IntegerNumberField
          name="age_input"
          label={translate("age.label")}
          value={age}
          onChange={updateAge}
          error={errors.age}
        />
      </Styles.TwoColumns>

      <Styles.ThreeColumns>
        <IntegerNumberField
          name="number_housed_animals_input"
          label={translate("number_housed_animals.label")}
          value={numberHousedAnimals}
          onChange={updateNumberHousedAnimals}
          error={errors.numberHousedAnimals}
        />

        <IntegerNumberField
          name="number_slaughtered_animals_input"
          label={translate("number_slaughtered_animals.label")}
          value={numberSlaughteredAnimals}
          onChange={updateNumberSlaughteredAnimals}
          error={errors.numberSlaughteredAnimals}
        />

        <IntegerNumberField
          name="number_dead_animals_input"
          label={translate("number_dead_animals.label")}
          value={numberDeadAnimals}
          onChange={updateNumberDeadAnimals}
          error={errors.numberDeadAnimals}
        />
      </Styles.ThreeColumns>

      <Styles.TwoColumns>
        <NumberField
          name="average_weight_housed_animals_input"
          label={translate("average_weight_housed_animals.label")}
          value={averageWeightHousedAnimals}
          onChange={updateAverageWeightHousedAnimals}
          error={errors.averageWeightHousedAnimals}
        />
        <NumberField
          name="total_weight_slaughtered_animals_input"
          label={translate("total_weight_slaughtered_animals.label")}
          value={totalWeightSlaughteredAnimals}
          onChange={updateTotalWeightSlaughteredAnimals}
          error={errors.totalWeightSlaughteredAnimals}
        />
      </Styles.TwoColumns>

      <Styles.TwoColumns>
        <NumberField
          name="weight_gain_animal_input"
          label={translate("weight_gain_animal.label")}
          value={weightGainAnimal}
          onChange={updateWeightGainAnimal}
          error={errors.weightGainAnimal}
        />
        <NumberField
          name="weight_gain_input"
          label={translate("weight_gain.label")}
          value={weightGain}
          onChange={updateWeightGain}
          error={errors.weightGain}
        />
      </Styles.TwoColumns>
    </>
  );
}
