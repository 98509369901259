import { Button, Divider } from "@/domains/_shared/components";
import {
  useCalculateForm,
  useFertilizersForm,
  useFugitiveEmissionsForm,
  useMobileCombustionForm,
  useStationaryCombustionForm,
  FertilizersTable,
  FugitiveEmissionsTable,
  MobileCombustionTable,
  StationaryCombustionTable,
  Result,
  usePigFarmingForm,
  PigFarmingTable,
  PoultryFarmingTable,
  usePoultryFarmingForm,
} from "@/domains/calculate-emissions";
import { useTranslation } from "@/domains/_shared/hooks";

import * as Styles from "./Overview.styles";

export function Overview() {
  const { translate } = useTranslation("calculate_form.overview");

  const { stationaryCombustionList } = useStationaryCombustionForm();
  const { mobileCombustionList } = useMobileCombustionForm();
  const { fugitiveEmissionsList } = useFugitiveEmissionsForm();
  const { list: fertilizersList } = useFertilizersForm();
  const { pigFarmingList } = usePigFarmingForm();
  const { poultryFarmingList } = usePoultryFarmingForm();

  const { calculate, isSuccess, data } = useCalculateForm();

  const calculateWithFormValues = async () => {
    await calculate({
      stationaryCombustion: stationaryCombustionList.items,
      mobileCombustion: mobileCombustionList.items,
      fugitiveEmissions: fugitiveEmissionsList.items,
      fertilizers: fertilizersList.items,
      pigFarming: pigFarmingList.items,
      poultryFarming: poultryFarmingList.items,
    });
  };

  if (isSuccess && data) {
    return <Result result={data} />;
  }

  return (
    <Styles.Container>
      <h2>{translate("title")}</h2>

      <Divider />

      <Styles.TableContainer>
        <StationaryCombustionTable />
        <MobileCombustionTable />
        <FugitiveEmissionsTable />
        <FertilizersTable />
        <PigFarmingTable />
        <PoultryFarmingTable />
      </Styles.TableContainer>

      <div>
        <Button onClick={calculateWithFormValues}>
          {translate("button.label")}
        </Button>
      </div>
    </Styles.Container>
  );
}
