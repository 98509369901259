import styled from "styled-components";

export const Select = styled.select`
  font-size: ${(props) => props.theme.components.shared.form.font_size};

  padding: 10px;
  border: 1px solid ${(props) => props.theme.colors.grey_1};
  border-radius: 4px;
  outline: none;
  cursor: pointer;

  background: ${(props) => props.theme.colors.background_light};

  option[selected] {
    background-color: #007bff;
    color: white;
  }
`;
