import { MobileCombustionFuels } from "@/domains/calculate-emissions/constrains";
import { Validator } from "@/domains/_shared/validators";
import {
  MobileCombustion,
  MobileCombustionForm,
} from "@/domains/calculate-emissions/context";

import { useStepFormBuilder } from "./useStepFormBuilder";

export function useMobileCombustion(): MobileCombustionForm {
  const {
    description,
    fuel,
    amount,
    updateValueCurry,
    errors,

    array,
    size,
    isEmpty,
    addItemToArray,
    removeItem,
  } = useStepFormBuilder<MobileCombustion>({
    defaultValues: {
      description: "",
      fuel: MobileCombustionFuels.AutomotiveGasolineCommercial,
      amount: "0,00",
    },
    validator: new Validator<MobileCombustion>({
      description: ["required"],
      amount: ["above_zero"],
    }),
    translationKey: "calculate_form.mobile_combustion.fields",
  });

  return {
    description,
    fuel,
    amount,
    errors,

    updateDescription: updateValueCurry("description"),
    updateFuel: updateValueCurry("fuel"),
    updateAmount: updateValueCurry("amount"),

    addMobileCombustion: addItemToArray,
    mobileCombustionList: {
      items: array,
      isEmpty,
      size,
      remove: removeItem,
    },
  };
}
