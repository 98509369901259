import styled from "styled-components";

export const Nav = styled.nav`
  background: ${(props) => props.theme.colors.text};

  padding: 12px;
`;

export const Title = styled.h3`
  color: white;
`;
