import { OrganicFertilizers } from "@/domains/calculate-emissions/constrains";
import { Select } from "@/domains/_shared/components";
import { useTranslation } from "@/domains/_app";

const ORGANIC_FERTILIZERS = Object.values(OrganicFertilizers);

export function OrganicFertilizerSelect(props: any) {
  const { translate } = useTranslation("calculate_form.fertilizers.fields");

  const { translate: translateFertilizer } = useTranslation(
    "constrains.organic_fertilizer"
  );

  const options = ORGANIC_FERTILIZERS.map((fertilizer) => ({
    value: fertilizer,
    label: translateFertilizer(fertilizer),
  }));

  return (
    <Select
      label={translate("organic_fertilizer.label")}
      options={options}
      {...props}
    />
  );
}
