import { useTranslation } from "@/domains/_shared/hooks";
import { DataTable } from "@/domains/_shared/components";
import { NumberFormatter } from "@/domains/_shared/formatters";

import { useFugitiveEmissionsForm, useTranslateGasOrCompound } from "../hooks";

export function FugitiveEmissionsTable() {
  const { translate } = useTranslation(
    "calculate_form.fugitive_emissions.table"
  );
  const { translateGasOrCompound } = useTranslateGasOrCompound();

  const { fugitiveEmissionsList } = useFugitiveEmissionsForm();

  return (
    <DataTable
      title={translate("title", { size: fugitiveEmissionsList.size })}
      columns={[
        translate("columns.gas_or_compound"),
        translate("columns.recharge"),
      ]}
      empty_title={translate("empty.title")}
      empty_subtitle={translate("empty.subtitle")}
      isEmpty={fugitiveEmissionsList.isEmpty}
      items={fugitiveEmissionsList.items.map((item) => {
        return [
          translateGasOrCompound(item.gasOrCompound),
          NumberFormatter.format(item.recharge),
        ];
      })}
      onRemove={fugitiveEmissionsList.remove}
    />
  );
}
