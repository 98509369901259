export class NumberFormatter {
  private static LOCALE = "pt-BR";
  private static NULL_NUMBER = "0,00";

  private static INITIAL_GROUP_SEPARATOR = /\./g;
  private static INITIAL_DECIMAL_SEPARATOR = ",";

  private static FINAL_GROUP_SEPARATOR = "";
  private static FINAL_DECIMAL_SEPARATOR = ".";

  static format(input: string): string;
  static format(input: number): string;
  static format(input: string | number): string {
    switch (typeof input) {
      case "number":
        return this.formatFromNumber(input);
      case "string":
        return this.formatFromString(input);

      default:
        return this.NULL_NUMBER;
    }
  }

  static formatPercentage(input: string) {
    return this.format(input) + "%";
  }

  static convertToFloat(input: string): number {
    return this.toFloat(this.clean(input));
  }

  private static formatFromString(input: string) {
    const number = this.convertToFloat(input);

    if (this.isNotValid(number)) {
      return this.NULL_NUMBER;
    }

    return this.toLocaleString(number);
  }

  private static formatFromNumber(input: number): string {
    return new Intl.NumberFormat("pt-BR").format(input);
  }

  private static clean(input: string) {
    return input
      .replace(this.INITIAL_GROUP_SEPARATOR, this.FINAL_GROUP_SEPARATOR)
      .replace(this.INITIAL_DECIMAL_SEPARATOR, this.FINAL_DECIMAL_SEPARATOR);
  }

  private static toFloat(input: string) {
    return parseFloat(input);
  }

  private static isNotValid(input: number) {
    return isNaN(input);
  }

  private static toLocaleString(input: number) {
    return input.toLocaleString(this.LOCALE, {
      minimumFractionDigits: 2,
    });
  }
}
