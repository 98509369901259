import { MobileCombustionFuels } from "@/domains/calculate-emissions/constrains";
import { Select } from "@/domains/_shared/components";

import { useTranslateFuel } from "../hooks";

const FUELS = Object.values(MobileCombustionFuels);

export function MobileFuelSelect(props: {
  fuel: MobileCombustionFuels;
  onChange: Function;
  label: string;
}) {
  const { translateFuel } = useTranslateFuel();

  const options = FUELS.map((fuel) => ({
    value: fuel,
    label: translateFuel(fuel),
  }));

  return (
    <Select
      name="fuel_select_input"
      label={props.label}
      options={options}
      value={props.fuel}
      onChange={props.onChange}
    />
  );
}
