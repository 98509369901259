import styled, { css } from "styled-components";

type Options = {
  hasRemoveOption?: boolean;
};

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 12px;

  border-spacing: 0;
`;

export const Header = styled.th<Options>`
  color: ${(props) => props.theme.colors.text};
  text-align: left;

  background-color: ${(props) => props.theme.colors.blue_100};
  padding: 10px;

  border: 1px solid ${(props) => props.theme.colors.blue_200};

  ${(props) =>
    props.hasRemoveOption &&
    css`
      &:last-child {
        max-width: 60px;
      }
    `}
`;

export const Data = styled.td<Options>`
  text-align: left;
  color: ${(props) => props.theme.colors.text};

  background-color: ${(props) => props.theme.colors.grey_100};
  padding: 10px;

  border: 1px solid ${(props) => props.theme.colors.grey_200};

  ${(props) =>
    props.hasRemoveOption &&
    css`
      &:last-child {
        text-align: center;
      }
    `}
`;
