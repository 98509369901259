import { IconBaseProps, IconType } from "react-icons";
import React, { MouseEventHandler } from "react";

interface InputProps extends IconBaseProps {
  icon: IconType;
}

interface IconProps {
  onClick?: MouseEventHandler<SVGElement>;
}

export function makeIcon({ icon: Icon, ...props }: InputProps) {
  return (iconProps: IconProps) => <Icon {...props} {...iconProps} />;
}
