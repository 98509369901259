import styled from "styled-components";

export const Label = styled.span`
  font-size: 18px;
`;

export const Container = styled.div`
  margin-top: 10px;
  margin-bottom: 16px;

  display: flex;
  flex-direction: column;
  gap: 8px;

  input.field_error,
  textarea.field_error {
    border-left: 4px solid ${(props) => props.theme.colors.red_600};

    transition: border-left-color 0.15s ease;
  }

  input:focus,
  textarea:focus,
  select:focus {
    border-color: ${(props) => props.theme.colors.blue_600};
    box-shadow: 0 0 0 3px ${(props) => props.theme.colors.blue_300};

    border-left: 4px solid ${(props) => props.theme.colors.blue_600};
  }

  input,
  textarea,
  select {
    border: 1px solid ${(props) => props.theme.colors.grey_300};
    border-radius: 2px;
    padding: 10px;

    transition: border 0.3s ease;
  }

  input,
  select {
    height: 46px;
  }

  input.date_field {
    width: 100%;
    font-size: 18px;
  }
`;
