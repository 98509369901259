import { ReactNode, useMemo } from "react";

import { Icon } from "@/domains/_shared/components";
import { useTranslation } from "@/domains/_shared/hooks";

import { Table } from "../Table";

interface DataTableProps {
  name?: string;

  title?: string;
  columns: string[];

  empty_title?: string;
  empty_subtitle?: string;

  items: ReactNode[][];
  isEmpty: boolean;

  onRemove?: (index: number) => void;
}

export function DataTable({
  name,

  title,
  columns,

  items,
  isEmpty,

  empty_title,
  empty_subtitle,

  onRemove,
}: DataTableProps) {
  const { translate } = useTranslation("constrains.data_table");

  const cachedData = useMemo(
    () =>
      onRemove
        ? items.map((item, index) => [
            ...item,
            <Icon.Remove onClick={() => onRemove(index)} />,
          ])
        : items,
    [onRemove, items]
  );

  const cachedColumns = useMemo(
    () => (onRemove ? [...columns, translate("columns.remove")] : columns),
    [onRemove, columns, translate]
  );

  if (isEmpty)
    return (
      <>
        <h3>{empty_title}</h3>
        <p>{empty_subtitle}</p>
      </>
    );

  return (
    <>
      {title && <h3>{title}</h3>}

      <Table
        name={name}
        columns={cachedColumns}
        data={cachedData}
        hasRemoveOption={!!onRemove}
      />
    </>
  );
}
