import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  CreateAxiosDefaults,
} from "axios";

import { TokenStorage } from "@/domains/authentication/services/token-storage";

export class AxiosClient {
  private readonly instance: AxiosInstance;

  constructor(private readonly storage: TokenStorage) {
    this.instance = axios.create(this.configuration);

    this.setUpInterceptors();
  }

  async fetch(config: AxiosRequestConfig) {
    return this.instance(config);
  }

  private setUpInterceptors() {
    this.setUpTokenInterceptor();
  }

  private setUpTokenInterceptor() {
    this.instance.interceptors.request.use(async (config) => {
      if (this.storage.token) {
        config.headers["Authorization"] = `Bearer ${this.storage.token}`;
      }

      return config;
    });
  }

  private get configuration(): CreateAxiosDefaults {
    return {
      timeout: 10000,
      baseURL: process.env.REACT_APP_API_URL,
    };
  }
}
