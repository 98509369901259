export const English = {
  finder: {
    header: {
      title: "GitHub Search",
    },
    repositories: {
      input: {
        placeholder: "Enter github username",
      },
      button: {
        submit: "Search",
      },

      item: {
        private: "Private",
        public: "Public",

        updated_at: "Updated at {{ date }}",
      },
    },

    components: {
      pagination: {
        container: {
          alt: "pagination",
        },

        previous: {
          text: "previous",
          alt: "go to previous page",
        },
        next: {
          text: "next",
          alt: "go to next page",
        },
      },
    },
  },
};
