import styled from "styled-components";

export const Container = styled.div``;

export const TableContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 16px;

  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
