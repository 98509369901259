import { DataTable } from "@/domains/_shared/components";
import { usePigFarmingForm } from "@/domains/calculate-emissions";
import { useTranslation } from "@/domains/_shared/hooks";
import { DateFormatter, NumberFormatter } from "@/domains/_shared/formatters";

export function PigFarmingTable() {
  const { translate } = useTranslation("calculate_form.pigfarming.table");
  const { translate: translateTreatment } = useTranslation(
    "constrains.pig_farming_treatments"
  );
  const { pigFarmingList } = usePigFarmingForm();

  return (
    <DataTable
      title={translate("title", { size: pigFarmingList.size })}
      empty_title={translate("empty.title")}
      empty_subtitle={translate("empty.subtitle")}
      columns={[
        translate("columns.treatment"),
        translate("columns.slaughter_date"),
        translate("columns.weight_gain"),
        translate("columns.age"),
      ]}
      items={pigFarmingList.items.map((item) => [
        translateTreatment(item.treatment),
        DateFormatter.fromDateToString(item.slaughterDate),
        NumberFormatter.format(item.weightGain),
        item.age,
      ])}
      isEmpty={pigFarmingList.isEmpty}
      onRemove={pigFarmingList.remove}
    />
  );
}
