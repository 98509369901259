import React from "react";

import { ButtonProps, Variants } from "./Button.types";
import * as Styled from "./Button.styles";

export const Button: React.ProviderFC<ButtonProps> = ({
  variant,
  ...htmlProps
}) => {
  return (
    // @ts-ignore
    <Styled.Button
      variant={variant || Variants.primary}
      {...htmlProps}
      data-testid={`@/${htmlProps.name}/container`}
    />
  );
};
