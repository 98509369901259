import React from "react";

import { FormField, TextFieldProps } from "@/domains/_shared/components";

import * as Styles from "./TextField.styles";

export const TextField: React.FC<TextFieldProps> = ({
  label,
  onChange,
  error,
  type = "text",
  ...htmlProps
}) => {
  const dispatchOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(event.target.value);
  };

  return (
    <FormField name={htmlProps.name} label={label} error={error}>
      <Styles.Input
        {...htmlProps}
        type={type}
        onChange={dispatchOnChange}
        className={error && "field_error"}
        role={type === "password" ? "password" : "textbox"}
      />
    </FormField>
  );
};
