import styled from "styled-components";

import { Grid } from "@/domains/_shared/components";

export const Container = styled.div``;

export const Spacer = styled.div`
  margin-top: 10px;
  margin-bottom: 16px;

  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const FullRight = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Split = styled(Grid)`
  grid-template-columns: 1fr 1fr;
`;
