import { MdClose } from "react-icons/md";
import { FaTrash } from "react-icons/fa";

import { theme } from "@/domains/_app/Providers/ThemeProvider";

import { makeIcon } from "./Icon.factory";

export const Icon = {
  Close: makeIcon({
    icon: MdClose,
    size: "1.5em",
    role: "button",
    style: {
      cursor: "pointer",
    },
  }),
  Remove: makeIcon({
    icon: FaTrash,
    size: "1.3em",
    role: "button",
    color: theme.colors.text,
    style: {
      cursor: "pointer",
    },
  }),
};
