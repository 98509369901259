export enum PigFarmingTreatments {
  UncoveredAnaerobicLagoon = "uncovered_anaerobic_lagoon",
  LiquidSlurryPitStorageAbove = "liquid_slurry_pit_storage_above",
  LiquidSlurryPitStorageBelow = "liquid_slurry_pit_storage_below",
  SolidStorage = "solid_storage",
  DryLot = "dry_lot",
  DailySpread = "daily_spread",
  AnaerobicDigestionBiogas = "anaerobic_digestion_biogas",
  BurnedForFuel = "burned_for_fuel",
}
