import { useNavigate } from "react-router-dom";

import { AppRoutes } from "@/domains/_shared/constrains";

export function useRouter() {
  const navigate = useNavigate();

  const goTo = (page: string) => navigate(page);

  return {
    goToCalculatePage: () => goTo(AppRoutes.calculate()),
  };
}
