import { NumberFieldProps } from "@/domains/_shared/components/NumberField/NumberField.types";
import { ExternalNumberField } from "@/domains/_shared/components/ExternalNumberField";

export function IntegerNumberField(props: NumberFieldProps) {
  return (
    <ExternalNumberField
      {...props}
      groupSeparator="."
      decimalSeparator=","
      allowDecimals={false}
      allowNegativeValue={false}
    />
  );
}
