export class Pipeline<CurrentValue> {
  constructor(private readonly value: CurrentValue) {}

  finish() {
    return this.value;
  }

  run<NextValue>(fn: (value: CurrentValue) => NextValue) {
    return new Pipeline(fn(this.value));
  }
}
