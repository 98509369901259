import React from "react";

import { Field } from "@/domains/_shared/models";

import { FieldError } from "../FieldError";

import * as Styles from "./FormField.styles";

export const FormField: React.ProviderFC<Field> = ({
  name,
  label,
  error,
  children,
}) => {
  return (
    <Styles.Container data-testid={`@/${name}/container`}>
      <Styles.Label>{label}</Styles.Label>

      {children}

      {error && <FieldError>{error}</FieldError>}
    </Styles.Container>
  );
};
