import React from "react";

import { FormField } from "../FormField";

import * as Styled from "./TextArea.styles";
import { TextAreaProps } from "./TextArea.types";

export const TextArea: React.FC<TextAreaProps> = ({
  label,
  error,
  onChange,
  ...htmlProps
}) => {
  const dispatchOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange?.(event.target.value);
  };

  return (
    <FormField name={htmlProps.name} label={label} error={error}>
      <Styled.TextArea
        {...htmlProps}
        onChange={dispatchOnChange}
        role="textbox"
        aria-label={htmlProps.name}
        className={error && "field_error"}
      />
    </FormField>
  );
};
