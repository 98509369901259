import { useState } from "react";

export function useArrayState<T>(initialArray: T[] = []) {
  const [array, setArray] = useState<T[]>(initialArray);

  const addItem = (item: T) => {
    setArray([...array, item]);
  };

  const removeItem = (index: number) => {
    const newArray = [...array];
    newArray.splice(index, 1);
    setArray(newArray);
  };

  const size = array.length;
  const isEmpty = size === 0;

  return { addItem, removeItem, array, size, isEmpty };
}
