import { Divider } from "@/domains/_shared/components";

import { StepFormLayoutProps } from "./StepFormLayout.types";
import * as Styles from "./StepFromLayout.styles";

export function StepFormLayout({
  title,
  table,
  form,
  button,
}: StepFormLayoutProps) {
  return (
    <Styles.Container>
      {typeof title === "string" ? <h2>{title}</h2> : title}

      <Divider />

      <Styles.TableContainer>{table}</Styles.TableContainer>

      <Divider />

      {form}

      <Styles.ButtonContainer>{button}</Styles.ButtonContainer>

      <Divider />
    </Styles.Container>
  );
}
