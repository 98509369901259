import { NumberField } from "@/domains/_shared/components";
import { useTranslation } from "@/domains/_shared/hooks";
import { GasOrCompoundSelect } from "@/domains/calculate-emissions";

import { useFugitiveEmissionsForm } from "../../hooks";

import * as Styles from "./FugitiveEmissions.styles";

export function Form() {
  const { translate } = useTranslation(
    "calculate_form.fugitive_emissions.fields"
  );

  const {
    gasOrCompound,
    recharge,
    updateGasOrCompound,
    updateRecharge,
    errors,
  } = useFugitiveEmissionsForm();

  return (
    <Styles.Container>
      <GasOrCompoundSelect
        label={translate("gas_or_compound.label")}
        value={gasOrCompound}
        onChange={updateGasOrCompound}
      />

      <NumberField
        label={translate("recharge.label")}
        name="recharge_input"
        value={recharge}
        onChange={updateRecharge}
        error={errors.recharge}
      />
    </Styles.Container>
  );
}
