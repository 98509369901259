import styled from "styled-components";

import { Field } from "@/domains/_shared/models";

export const TextArea = styled.textarea<Field>`
  font-size: ${(props) => props.theme.components.shared.form.font_size};

  padding: 10px;
  outline: none;
  cursor: pointer;

  resize: none;
`;
