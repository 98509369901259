import { FormField } from "@/domains/_shared/components";

import { ExternalDateField } from "./ExternalDateField";
import { DateFieldProps } from "./DateField.types";

export function DateField({
  value,
  onChange,
  blockFutureDates,
  ...fieldProps
}: DateFieldProps) {
  return (
    <FormField {...fieldProps}>
      <ExternalDateField
        selected={value}
        dateFormat="dd/MM/yyyy"
        onChange={onChange as (date: Date | null) => void}
        maxDate={blockFutureDates ? new Date() : null}
        className={fieldProps.error ? "date_field field_error" : "date_field"}
      />
    </FormField>
  );
}
