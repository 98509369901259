import React from "react";

import { AuthProvider } from "@/domains/_app/Router";

import { ThemeProvider } from "./ThemeProvider";
import { ReactQueryProvider } from "./ReactQueryProvider";

export const Providers: React.ProviderFC = ({ children }) => (
  <ReactQueryProvider>
    <ThemeProvider>
      <AuthProvider>{children}</AuthProvider>
    </ThemeProvider>
  </ReactQueryProvider>
);
