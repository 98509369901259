import { ReactNode } from "react";

import * as Styles from "./Tabel.styles";

interface TableProps {
  name?: string;

  hasRemoveOption?: boolean;

  columns: ReactNode[];
  data: ReactNode[][];
}

export function Table({ name, columns, data, hasRemoveOption }: TableProps) {
  const makeKey = (data: ReactNode, index: number) => String(data) + index;

  return (
    <Styles.Table data-testid={name && `@/table/${name}/container`}>
      <thead>
        <tr>
          {columns.map((column, index) => (
            <Styles.Header
              key={makeKey(column, index)}
              hasRemoveOption={hasRemoveOption}
            >
              {column}
            </Styles.Header>
          ))}
        </tr>
      </thead>

      <tbody>
        {data.map((row, rowIndex) => (
          <tr key={makeKey(row, rowIndex)}>
            {row.map((cell, cellIndex) => (
              <Styles.Data
                key={makeKey(cell, cellIndex)}
                hasRemoveOption={hasRemoveOption}
              >
                {cell}
              </Styles.Data>
            ))}
          </tr>
        ))}
      </tbody>
    </Styles.Table>
  );
}
