import React from "react";

import { Select } from "@/domains/_shared/components";
import { useTranslation } from "@/domains/_shared/hooks";

const SECTORS = [
  "energy",
  "manufacturing_or_construction",
  "commercial_or_institutional",
  "residential_agriculture_forestry_or_fishing",
];

export function StationarySectorSelect() {
  const { translate } = useTranslation("calculate_form.stationary_combustion");
  const { translate: translateSector } = useTranslation(
    "constrains.stationary_combustion_sector"
  );

  const options = SECTORS.map((sector) => ({
    value: sector,
    label: translateSector(sector),
  }));

  return <Select label={translate("fields.sector.label")} options={options} />;
}
