import { StationaryCombustionFuels } from "@/domains/calculate-emissions/constrains";
import { Validator } from "@/domains/_shared/validators";
import {
  StationaryCombustion,
  StationaryCombustionForm,
} from "@/domains/calculate-emissions/context";

import { useStepFormBuilder } from "./useStepFormBuilder";

export function useStationaryCombustion(): StationaryCombustionForm {
  const {
    description,
    fuel,
    amount,
    updateValueCurry,
    errors,

    array,
    size,
    isEmpty,
    addItemToArray,
    removeItem,
  } = useStepFormBuilder<StationaryCombustion>({
    defaultValues: {
      description: "",
      fuel: StationaryCombustionFuels.Acetylene,
      amount: "0,00",
    },
    validator: new Validator<StationaryCombustion>({
      description: ["required"],
      amount: ["above_zero"],
    }),
    translationKey: "calculate_form.stationary_combustion.fields",
  });

  return {
    description,
    fuel,
    amount,
    errors,

    updateDescription: updateValueCurry("description"),
    updateFuel: updateValueCurry("fuel"),
    updateAmount: updateValueCurry("amount"),

    addStationaryCombustion: addItemToArray,
    stationaryCombustionList: {
      items: array,
      isEmpty,
      size,
      remove: removeItem,
    },
  };
}
