import { useState } from "react";

export enum Steps {
  STATIONARY_COMBUSTION = 1,
  MOBILE_COMBUSTION,
  FUGITIVE_EMISSIONS,
  FERTILIZERS,
  PIG_FARMING,
  POULTRY_FARMING,
  OVERVIEW,
}

export function useStepper() {
  const [step, setStep] = useState(Steps.STATIONARY_COMBUSTION);

  const isFirstStep = step === Steps.STATIONARY_COMBUSTION;
  const isLastStep = step === Steps.OVERVIEW;

  const goToNext = () => {
    if (isLastStep) return;

    setStep(step + 1);
  };

  const goBack = () => {
    if (isFirstStep) return;

    setStep(step - 1);
  };

  return {
    current: step,
    goToNext,
    goBack,

    isFirstStep,
    isLastStep,
  };
}
