import { NumberFormatter } from "@/domains/_shared/formatters";

import { RuleValidator } from "./validator.types";

export class AboveZeroValidator<T> implements RuleValidator<T> {
  isNotValid(value: T): boolean {
    if (typeof value !== "string") return false;

    return NumberFormatter.convertToFloat(value) <= 0;
  }

  getError(field: string): string {
    return `${field}.errors.below_zero`;
  }
}
