import React, { createContext, useMemo } from "react";

import {
  useStationaryCombustion,
  useMobileCombustion,
  useFugitiveEmissions,
  useFertilizers,
  usePigFarming,
  usePoultryFarming,
} from "../hooks";

import { FormContextValue } from "./Form.context.types";

export const FormContext = createContext<FormContextValue>(
  {} as FormContextValue
);

export const FormProvider: React.ProviderFC = ({ children }) => {
  const stationaryCombustion = useStationaryCombustion();
  const mobileCombustion = useMobileCombustion();
  const fugitiveEmissions = useFugitiveEmissions();
  const fertilizers = useFertilizers();
  const pigFarming = usePigFarming();
  const poultryFarming = usePoultryFarming();

  const cachedValue = useMemo(() => {
    return {
      stationaryCombustion,
      mobileCombustion,
      fugitiveEmissions,
      fertilizers,
      pigFarming,
      poultryFarming,
    };
  }, [
    stationaryCombustion,
    mobileCombustion,
    fugitiveEmissions,
    fertilizers,
    pigFarming,
    poultryFarming,
  ]);

  return (
    <FormContext.Provider value={cachedValue}>{children}</FormContext.Provider>
  );
};
