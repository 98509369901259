import { AxiosResponse, isAxiosError } from "axios";

import { HttpRequest, HttpResponse } from "./http-client.types";
import { AxiosClient } from "./axios";

export class HttpClient {
  constructor(private readonly axios: AxiosClient) {}

  async request<T>(httpRequest: HttpRequest): Promise<HttpResponse<T>> {
    try {
      const apiResponse: AxiosResponse<T> = await this.axios.fetch({
        data: httpRequest.body,
        method: httpRequest.method,
        url: httpRequest.url,
        params: httpRequest.queryParams,
      });

      return {
        body: apiResponse?.data,
        statusCode: apiResponse?.status,
      };
    } catch (error) {
      if (!isAxiosError(error)) {
        throw error;
      }

      return {
        statusCode: error.response!.status,
        body: error.response?.data,
        error,
      };
    }
  }
}
