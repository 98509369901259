import {
  PigFarmingTable,
  StepFormLayout,
  usePigFarmingForm,
} from "@/domains/calculate-emissions";
import { Button } from "@/domains/_shared/components";
import { useTranslation } from "@/domains/_app";

import { Form } from "./PigFarming.form";

export function PigFarming() {
  const { translate } = useTranslation("calculate_form.pigfarming");

  const { addFarmingToList } = usePigFarmingForm();

  return (
    <StepFormLayout
      title={translate("title")}
      table={<PigFarmingTable />}
      form={
        <>
          <h3>{translate("subtitle")}</h3>

          <Form />
        </>
      }
      button={
        <Button name="add_button" onClick={addFarmingToList}>
          {"adicionar"}
        </Button>
      }
    />
  );
}
