import DatePicker, { ReactDatePickerProps } from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "./setup-location";

interface ExternalDateFieldProps extends ReactDatePickerProps {}

export function ExternalDateField(props: ExternalDateFieldProps) {
  return <DatePicker {...props} locale="pt" />;
}
