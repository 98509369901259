import { useContext } from "react";

import {
  FugitiveEmissionsForm,
  MobileCombustionForm,
  StationaryCombustionForm,
  FertilizerForm,
  FormContext,
  PigFarmingForm,
  PoultryFarmingForm,
} from "@/domains/calculate-emissions/context";

export function useStationaryCombustionForm(): StationaryCombustionForm {
  return useContext(FormContext).stationaryCombustion;
}

export function useMobileCombustionForm(): MobileCombustionForm {
  return useContext(FormContext).mobileCombustion;
}

export function useFugitiveEmissionsForm(): FugitiveEmissionsForm {
  return useContext(FormContext).fugitiveEmissions;
}

export function useFertilizersForm(): FertilizerForm {
  return useContext(FormContext).fertilizers;
}

export function usePigFarmingForm(): PigFarmingForm {
  return useContext(FormContext).pigFarming;
}

export function usePoultryFarmingForm(): PoultryFarmingForm {
  return useContext(FormContext).poultryFarming;
}
