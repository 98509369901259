import { useState } from "react";

import { Pipeline } from "@/domains/_shared/models";

type Errors<T> = Record<keyof T, string>;

function getDefaultErrors<T>(values: T): Errors<T> {
  return new Pipeline<T>(values)
    .run((value) => Object.keys(value as object))
    .run((value) => value.map((key) => [key, ""]))
    .run((value) => Object.fromEntries(value))
    .finish();
}

export function useFormBuilder<T>(defaultValues: T) {
  const [values, setValues] = useState<T>(defaultValues as T);

  const [errors, setErrors] = useState<Errors<T>>(
    getDefaultErrors(defaultValues)
  );

  const updateValue = <K extends keyof T>(key: K, value: T[K]) => {
    setValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  const updateValueCurry =
    <K extends keyof T>(key: K) =>
    (value: T[K]) => {
      updateValue(key, value);
    };

  const updateErrors = (errorObject: Partial<T>) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      ...errorObject,
    }));
  };

  const resetErrors = () => {
    setErrors(getDefaultErrors(defaultValues));
  };

  return {
    ...values,
    updateValue,
    updateValueCurry,

    errors,
    updateErrors,
    resetErrors,
  };
}
