import { GasOrCompound } from "@/domains/calculate-emissions/constrains";
import { Validator } from "@/domains/_shared/validators";
import {
  FugitiveEmissions,
  FugitiveEmissionsForm,
} from "@/domains/calculate-emissions/context";

import { useStepFormBuilder } from "./useStepFormBuilder";

export function useFugitiveEmissions(): FugitiveEmissionsForm {
  const {
    gasOrCompound,
    recharge,
    updateValueCurry,

    errors,

    array,
    isEmpty,
    addItemToArray,
    size,
    removeItem,
  } = useStepFormBuilder<FugitiveEmissions>({
    translationKey: "calculate_form.fugitive_emissions.fields",
    defaultValues: {
      gasOrCompound: GasOrCompound.CO2,
      recharge: "0,00",
    },
    validator: new Validator<FugitiveEmissions>({
      recharge: ["above_zero"],
    }),
  });

  return {
    gasOrCompound,
    recharge,

    errors,

    updateGasOrCompound: updateValueCurry("gasOrCompound"),
    updateRecharge: updateValueCurry("recharge"),

    addFugitiveEmissions: addItemToArray,
    fugitiveEmissionsList: {
      items: array,
      size,
      isEmpty,
      remove: removeItem,
    },
  };
}
