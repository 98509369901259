import { useState } from "react";

import { Button, TextField } from "@/domains/_shared/components";
import { useAuth, useRouter, useTranslation } from "@/domains/_shared/hooks";

import * as Styles from "./Login.styles";

export function Login() {
  const { translate } = useTranslation("login");
  const { goToCalculatePage } = useRouter();
  const [password, setPassword] = useState("");

  const { onLogin } = useAuth();

  const loginThenRedirect = async () => {
    const isAuthenticated = await onLogin(password);

    if (isAuthenticated) {
      goToCalculatePage();
    }
  };

  return (
    <Styles.Container>
      <Styles.FormContainer>
        <TextField
          name="password_input"
          label={translate("password.label")}
          type="password"
          value={password}
          onChange={setPassword}
        />

        <Button name="login_button" onClick={loginThenRedirect}>
          {translate("button.label")}
        </Button>
      </Styles.FormContainer>
    </Styles.Container>
  );
}
