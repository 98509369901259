import { useFertilizersForm } from "@/domains/calculate-emissions";
import { DataTable } from "@/domains/_shared/components";
import { NumberFormatter } from "@/domains/_shared/formatters";
import { useTranslation } from "@/domains/_shared/hooks";
import { Pipeline } from "@/domains/_shared/models";

function sum<T extends { amount: string }>(list: T[]) {
  const total = list.reduce(
    (total, item) =>
      new Pipeline(item.amount)
        .run((amount) => NumberFormatter.convertToFloat(amount))
        .run((amount) => amount + total)
        .finish(),
    0
  );

  return NumberFormatter.format(total);
}

export function FertilizersTable() {
  const { translate } = useTranslation("calculate_form.fertilizers.table");
  const { list } = useFertilizersForm();

  return (
    <DataTable
      name="fertilizers_table"
      title={translate("title", { size: list.items.length })}
      empty_title={translate("empty.title")}
      empty_subtitle={translate("empty.subtitle")}
      columns={[
        translate("column.area"),
        translate("column.culture"),
        translate("column.organic_fertilizer"),
        translate("column.nitrogenous_fertilizer"),
        translate("column.urea"),
        translate("column.limestone"),
      ]}
      items={list.items.map((item) => {
        return [
          item.totalArea,
          item.culture,
          sum(item.organicFertilizer),
          sum(item.npkFertilizer),
          sum(item.ureaFertilizer),
          sum(item.limestoneFertilizer),
        ];
      })}
      isEmpty={list.isEmpty}
      onRemove={list.remove}
    />
  );
}
