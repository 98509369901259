import styled from "styled-components";

import { Grid } from "@/domains/_shared/components";

type EmissionProps = {
  type: "fossil" | "bio";
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  margin-bottom: 12px;

  color: ${(props) => props.theme.colors.text};
`;

export const TwoColumns = styled(Grid)`
  grid-template-columns: 1fr 1fr;
`;

export const EmissionTitle = styled.div<EmissionProps>`
  font-size: 20px;
  color: ${(props) => props.theme.colors.text};
  background: ${(props) => props.theme.colors.grey_150};

  border-radius: 2px;

  border-top: 4px solid
    ${(props) =>
      props.type === "fossil"
        ? props.theme.colors.blue_800
        : props.theme.colors.green_800};

  padding: 20px 12px;
`;

export const EmissionInfo = styled.p`
  font-size: 24px;
  font-weight: bold;

  margin-top: 14px;
`;
