import { ReactNode } from "react";

import { TotalsEmissions } from "@/domains/calculate-emissions/models";
import { useTranslateResult } from "@/domains/calculate-emissions";

import * as Styles from "../Results.styles";

interface TotalsByStepProps extends Partial<TotalsEmissions> {
  title: string;

  children?: ReactNode;
}

export function TotalsByStep({
  title,
  totalCo2EquivalentEmissionsMetricTonnes: totals,
  totalBiogenicCo2EmissionsMetricTonnes: biogenicTotals,

  children,
}: TotalsByStepProps) {
  const { translate, toDisplayNumber, isNotBlank } = useTranslateResult();

  return (
    <Styles.Container>
      <h2>{title}</h2>

      <Styles.TwoColumns>
        {isNotBlank(totals) && (
          <Styles.EmissionTitle type="fossil">
            {translate("total_co2_equivalent")}

            <Styles.EmissionInfo>
              {toDisplayNumber(totals as number)}
            </Styles.EmissionInfo>
          </Styles.EmissionTitle>
        )}

        {isNotBlank(biogenicTotals) && (
          <Styles.EmissionTitle type="bio">
            {translate("total_biogenic_co2")}

            <Styles.EmissionInfo>
              {toDisplayNumber(biogenicTotals as number)}
            </Styles.EmissionInfo>
          </Styles.EmissionTitle>
        )}
      </Styles.TwoColumns>

      {children}
    </Styles.Container>
  );
}
