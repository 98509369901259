import { CalculateGeeEmissionOutput } from "@/domains/calculate-emissions";
import { useTranslation } from "@/domains/_shared/hooks";

import { PigFarmingTotals, TotalsByStep } from "./components";
import * as Styles from "./Results.styles";

interface ResultProps {
  result: CalculateGeeEmissionOutput;
}

export function Result({ result }: ResultProps) {
  const { translate } = useTranslation("calculate_form.result");

  return (
    <Styles.Container>
      <TotalsByStep
        title={translate("titles.stationary_combustion")}
        {...result.stationaryCombustion.totals}
      />

      <TotalsByStep
        title={translate("titles.mobile_combustion")}
        {...result.mobileCombustion.totals}
      />

      <TotalsByStep
        title={translate("titles.fugitive_emissions")}
        {...result.fugitiveEmissions.totals}
      />

      <TotalsByStep
        title={translate("titles.fertilizers")}
        {...result.fertilizers.totals}
      />

      <TotalsByStep
        title={translate("titles.pig_farming")}
        {...result.pigFarming.totals}
      >
        <PigFarmingTotals {...result.pigFarming.totals} />
      </TotalsByStep>

      <TotalsByStep
        title={translate("titles.poultry_farming")}
        {...result.poultryFarming.totals}
      />
    </Styles.Container>
  );
}
