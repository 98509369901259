import { AuthService } from "@/domains/authentication/services/auth";
import { EmissionCalculatorService } from "@/domains/calculate-emissions/services/emission-calculator";
import { HttpClient } from "@/domains/_app/http/http-client";
import { TokenStorage } from "@/domains/authentication/services/token-storage";

import { AxiosClient } from "./http/axios/axios-client";

function createContainer() {
  const tokenStorage = new TokenStorage();
  const axiosClient = new AxiosClient(tokenStorage);
  const httpClient = new HttpClient(axiosClient);

  return {
    TokenStorage: tokenStorage,
    HttpClient: httpClient,
    AuthService: new AuthService(httpClient, tokenStorage),
    EmissionCalculatorService: new EmissionCalculatorService(httpClient),
  };
}

export const Container = createContainer();
