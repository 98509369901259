import { PigFarmingTreatments } from "@/domains/calculate-emissions/constrains/pig-farming";
import { Select } from "@/domains/_shared/components";
import { useTranslation } from "@/domains/_app";

const TREATMENTS = Object.values(PigFarmingTreatments);

export function TreatmentSelect(props: any) {
  const { translate } = useTranslation("constrains.pig_farming_treatments");

  const options = TREATMENTS.map((treatment) => ({
    value: treatment,
    label: translate(treatment),
  }));

  return <Select options={options} {...props} />;
}
