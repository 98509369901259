import React from "react";

import { Providers, GlobalStyle } from "./Providers";
import { Routes } from "./Router";

import "@/domains/_app/translation/translation.setup";

export function App() {
  return (
    <Providers>
      <Routes />

      <GlobalStyle />
    </Providers>
  );
}
