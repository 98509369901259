import { RuleValidator } from "./validator.types";

export class RequiredDateValidator<T> implements RuleValidator<T> {
  isNotValid(value: T): boolean {
    if (value instanceof Date) {
      return isNaN(value.getTime());
    }

    if (value instanceof String) {
      return true;
    }

    return true;
  }

  getError(field: string): string {
    return `${field}.errors.blank`;
  }
}
